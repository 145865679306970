// Actions
export const SET_TOKEN = "SET_TOKEN";

// Statut initial
export const INITIAL_STATE = {
  /**
   * Objet de token sauvegardé dans le store
   * @category Store
   * @typedef {object} TokenObject
   * @property {string} [token=null] Token
   * @property {date} [date=null] Date et heure de la récupération du token
   */
  token: {
    token: null,
    date: null
  }
};
