import { RESSOURCE_ACHATS, RESSOURCE_SERVICES, RESSOURCE_TARIFS, RESSOURCE_ZONE } from "../API/AchatApi";
import { PAYZEN, PAYZEN_TPE, TPE } from "../Constants";
import { setAchat } from "../Store/actions";
import { AchatList } from "./AchatList";
import { ModaleAttentePaiementTPE } from "./ModaleAttentePaiementTPE";
import { ModaleTypePaiement } from "./ModaleTypePaiement";
import { selectToken } from "App/Authentification/Store/selectors";
import { URL_BASE } from "App/Constants";
import { setBackVisible } from "App/Header/Store/actions";
import { Modale } from "App/Modale/Components/Modale";
import { showError } from "App/Toast/Toast";
import _ from "lodash";
import moment from "moment";
import { PaymentForm } from "PaymentForm/Components/PaymentForm";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectDataReferentielServiceTypes, selectDataReferentielUniteTypes } from "Referentiel/Store/selectors";
import { GET, POST } from "Request/Components/RequestUtils";
import { useJsonRequest, GET_LIST_WITH_PARAMS } from "Request/Components/useJsonRequest";
import { selectDataResourceReceived, selectDataResourceUpdated } from "Request/Store/selectors";
import { TicketClientModale } from "TicketClient/Components/TicketClientModale";
import { Fab } from "@rmwc/fab";
import { List } from "@rmwc/list";
import { TextField } from "@rmwc/textfield";
import { ConfirmModal } from "Shared/ConfirmModal";

import "@rmwc/fab/styles";
import "@rmwc/snackbar/styles";
import "@rmwc/list/styles";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./Achat.css";
import { Snackbar, SnackbarAction } from "@rmwc/snackbar";


// #region CONSTANTES
// Status du paiement
const PAID = "00";
// #endregion

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher la liste pour achat sur une borne
 * @category Achat
 */
function Achat() {
  //#region INITIALISATION
  // Récupération des props
  const location = useLocation();
  const borne = location.state.borne;

  // Initialisation du state
  const [data, setData] = useState(null);
  const [immatriculation, setImmatriculation] = useState(location.state.immatriculation ?? "");
  const [montantTotal, setMontantTotal] = useState(0);
  const [montantTaxes, setMontantTaxes] = useState(0); //SH 2023.05.31
  const [modaleAttentePaiementTPEVisible, setModaleAttentePaiementTPEVisible] = useState(false);
  const [modaleChoixPaiementVisible, setModaleChoixPaiementVisible] = useState(false);
  const [modaleImmatriculation, setModaleImmatriculation] = useState(false)
  const [modalePayzenVisible, setModalePayzenVisible] = useState(false);
  const [modaleTicketPayzenVisible, setTicketClientModaleVisible] = useState(false);
  const [messageImmatriculation, setMessageImmatriculation] = useState(false);
  const [showValidCommand, setShowValidCommand] = useState(true);
  const [modaleTaxe, setModaleTaxe] = useState(false) //SH 2023.06.07

  // Initialisation des selecteurs
  const dataUpdatedAchats = useSelector((state) => selectDataResourceUpdated(state, RESSOURCE_ACHATS)); // Données issues de la requête
  const dataReceivedServices = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_SERVICES)); // Données issues de la requête
  const dataReceivedTarifs = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_TARIFS)); // Données issues de la requête
  const dataReceivedZone = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_ZONE)); // Données issues de la requête de zone
  const token = useSelector((state) => selectToken(state));
  const serviceTypes = useSelector((state) => selectDataReferentielServiceTypes(state));
  const uniteTypes = useSelector((state) => selectDataReferentielUniteTypes(state));

  // Initialisation des références
  const achat = useRef(null); // Achat pour le ticket PAYZEN
  const achatTPEId = useRef(null); // Id de l'achat dans le cadre d'un paiement TPE
  const codeAcces = useRef(location.state.codeAcces) // Code d'accès dans le cas du rachat
  const countReqResult = useRef(0); // Nombre de requêtes effectuées
  const dataPlaces = useRef(null) // Nombre de places de parking disponibles
  const dataServices = useRef(null); // Services du terminal
  const dataTarifs = useRef(null); // Tarifs du terminal
  const decalage = useRef(null); // Date d'activation de la borne en cas de paiement TPE
  const detailsAchat = useRef([]); // Details de l'achat effectué
  const devise = useRef(null); // Devise de l'achat
  const doShowRecapitulatif = useRef(false); // True pour affichage des élements selectionnés uniquement
  const hasParking = useRef(false); // Définit si le service de stationnement est présent
  const typePaiement = useRef(null); // Type de paiement

  const hasTax = useRef(false);  //SH 2023.05.30
  const lastServicePark = useRef(false); //SH 2023.05.30
  const lastServiceTax = useRef(false); //SH 2023.06.07
  const nbPersonne = useRef(0);//SH 2023.06.07
  const qtParking = useRef(0);//SH 2023.06.07

  // Récupération du dispatch
  const dispatch = useDispatch();

  // Traduction i18n
  const { t } = useTranslation();
  // #endregion

  // #region REQUEST
  // Envoi de l'achat au serveur
  const doPostAchat = (achat, typePaiement = "Payzen") => {
    // Création de l'achat transmis à l'API Urbaflux
    let now = moment().format();
    let newAchat = {
      adresseMail: null,
      autorisationPayzen: null,
      CodeAcces: codeAcces.current,
      dateAchat: now,
      dateCreation: now,
      detailAchats: detailsAchat.current,
      idSite: borne.id,
      immatriculation: immatriculation,
      modePaiement: (borne.zones[0].paiementProd === 0 ? "TEST" : "PROD") || "INCONNU",
      montant: parseFloat((montantTotal / 100).toFixed(2)),
      numeroCarte: null,
      presenceUser: 0,
      transactionIdPayzen: null,
      typePaiement: typePaiement,
      statusPayzen: null
    };

    // Si paiement payzen, on met à jour les champs avec les valeurs récupérées dans PaymentForm
    if (typePaiement === "Payzen") {
      newAchat = { ...newAchat, ...achat }
    }

    requestPostAchat(newAchat);
  };

  // Requête de récupération des services
  const requestGetServices = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_SERVICES,
    url: URL_BASE,
  });

  // Requête de récupération des tarifs
  const requestGetTarifs = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_TARIFS,
    url: URL_BASE,
  });

  // Requête de récupération des informations du terminal
  const requestGetZone = useJsonRequest({
    command: GET,
    getMode: GET_LIST_WITH_PARAMS,
    resource: RESSOURCE_ZONE,
    url: URL_BASE,
  });

  // Requête de création d'un achat
  const requestPostAchat = useJsonRequest({
    command: POST,
    resource: RESSOURCE_ACHATS,
    url: URL_BASE,
  });
  // #endregion

  // #region UTILS
  // Préparation des données
  const initializeData = () => {
    if (countReqResult.current !== 3) {
      return;
    }

    let services = [...dataServices.current];
    let tarifs = [...dataTarifs.current];

    // Pour chaque service, on injecte le tarif du service type correspondant
    services.forEach(service => {
      let tarif = tarifs.find(tarif => tarif.idServiceType === service.idServiceType);
      service["tarif"] = tarif;
      if (service.idServiceType === 4) {
        service["places"] = dataPlaces.current;
        service["depassement"] = location.state.depassement
      }
      if (service.idServiceType === 10) {
        hasTax.current = true;
        if (tarif.idUniteType === 12) {
          nbPersonne.current = 2;
        }
      }

    });

    // Regroupement des services par serviceType
    let groupedData = _.groupBy(services, "idServiceType");
    let groupedArray = [];

    Object.keys(groupedData).forEach(key => groupedArray.push({
      id: key,
      name: groupedData[key][0].serviceType,
      services: groupedData[key]
    }));

    // Enregistrement du state
    setData(groupedArray);
  };

  // Sauvegarde de l'achat dans le store
  const storeAchat = useCallback((newAchat) => {
    // Calcul de la validité de l'idAchat
    // Récupération de la valeur max de quantite
    let max = _.maxBy(detailsAchat.current, detail => detail.quantite * detail.secondes)

    // Calcul de la date de validité
    let dateValidite = location.state.dateValidite
      ?
      moment(location.state.dateValidite).add(max.quantite * max.secondes + 20, "second").format()
      :
      moment().add(max.quantite * max.secondes + 20, "second").format();

    let dateDecalage = location.state.dateValidite
      ?
      moment(location.state.dateValidite)
      :
      moment();

    // Sauvegarde de l'idAchat dans le store
    dispatch(setAchat({
      codeAcces: newAchat.CodeAcces,
      date: dateValidite,
      id: newAchat.idAchat,
      idSite: newAchat.idSite,
      immatriculation: newAchat.immatriculation,
      presence: false,
      rachat: location.state.rachat,
      terminalNom: borne.name,
      decalage: decalage.current ? dateDecalage : null
    }));
  }, [dispatch, borne]);
  // #endregion

  // #region EVENTS
  // Modification du montant selectionné
  const handleChange = (value) => {
    let newMontant = 0;
    let newTaxe = Math.round((montantTaxes) * 100) / 100;
    newMontant = Math.round((montantTotal + value) * 100) / 100;

    //SH 2023.08.21
    if (!hasParking) {
      newTaxe = 0;
    }

    //if((hasTax ) && (hasParking.current) && (montantTaxes>0))
    if ((hasTax.current) && (hasParking.current) && (montantTaxes > 0)) {
      let findServiceTax = dataServices.current.findIndex(detail => detail.idServiceType === 10);
      let serviceTaxe = dataServices.current[findServiceTax];
      let tarif = dataTarifs.current.find(tarif => tarif.idServiceType === serviceTaxe.idServiceType);
      if (tarif.idUniteType === 12) {
        newTaxe = Math.round((qtParking.current * nbPersonne.current * tarif.prix) * 100) / 100;
        newMontant = Math.round(((newMontant - montantTaxes) + newTaxe) * 100) / 100;
        //showError("Apres modif " +newMontant);
      }
    }

    //if(lastServicePark.current && hasTax.current && value!==0)
    if (lastServicePark.current && hasTax.current && hasParking.current && value !== 0) //test 2028-08-21
    {
      let findServiceTax = dataServices.current.findIndex(detail => detail.idServiceType === 10);
      if (findServiceTax !== -1) {
        let serviceTaxe = dataServices.current[findServiceTax];
        let tarif = dataTarifs.current.find(tarif => tarif.idServiceType === serviceTaxe.idServiceType);
        if (newMontant > montantTotal) {

          if (tarif.idUniteType === 12) //personne
          {
            newMontant = Math.round((newMontant + (tarif.prix * nbPersonne.current)) * 100) / 100;
            newTaxe = Math.round((newTaxe + (tarif.prix * nbPersonne.current)) * 100) / 100;

          } else {
            newMontant = Math.round((newMontant + tarif.prix) * 100) / 100;
            newTaxe = Math.round((newTaxe + tarif.prix) * 100) / 100;
          }

          // setMontantTaxes(newTaxe);
          //showError("Ajout taxe sejour valeur park "+value);
        }
        else {

          if (tarif.idUniteType === 12) {
            newMontant = Math.round((newMontant - (tarif.prix * nbPersonne.current)) * 100) / 100;
            newTaxe = Math.round((newTaxe - (tarif.prix * nbPersonne.current)) * 100) / 100;
          }
          else {
            newMontant = Math.round((newMontant - tarif.prix) * 100) / 100;
            newTaxe = Math.round((newTaxe - tarif.prix) * 100) / 100;
          }

          //setMontantTaxes(newTaxe);
          //showError("Suppression taxe sejour valeur park "+value);
        }

      }

    }
    else {


    }


    setMontantTaxes(newTaxe);
    /* if(newMontant<0)
     {
       showError("Montant < 0");
       newMontant = 0 ;
     }*/
    //fin SH

    setMontantTotal(newMontant);
    lastServicePark.current = false;
    lastServiceTax.current = false;
  };

  // Annulation de la demande de validation
  const handleClickAnnuler = () => {
    // Annulation de l'affichage du récapitulatif
    doShowRecapitulatif.current = false;

    // Affichage des boutons pour valider la commande
    setShowValidCommand(true);
  };

  // Choix du mode de paiement
  const handleClickChoixModePaiement = (type) => {
    // Fermeture de la modale de choix de paiement
    setModaleChoixPaiementVisible(false);

    switch (type) {
      // Choix PAYZEN
      case PAYZEN:
        // Ouverture de la modale de paiement PAYZEN
        setModalePayzenVisible(true);
        break;

      // CHOIX TPE
      case PAYZEN_TPE:
        // Post de l'achat
        doPostAchat(null, TPE);
        break;

      default:
        break;
    }
  };

  // Click sur le bouton Acheter
  const handleClickPay = (props) => {
    let isParking = detailsAchat.current.filter(item => item.serviceType === 4 || item.serviceType === 9).length > 0;
    // Si service de stationnement acheté, gestion de l'immatriculaton
    if (!props && isParking) {
      if (immatriculation) {
        setModaleImmatriculation(true);
      } else {
        setMessageImmatriculation(true);
      }
      return;
    }

    switch (borne.idModePaiement) {
      case PAYZEN:
        setModalePayzenVisible(true);
        break;

      case PAYZEN_TPE:
        setModaleChoixPaiementVisible(true);
        decalage.current = true;
        break;

      default:
        break;
    }
  };

  // Demande de validation de la commande
  const handleClickValidCommand = () => {


    if (!montantTotal) {
      showError("Merci de sélectionner au moins un service");
      return;
    }


    if ((montantTaxes) > 0 && (!modaleTaxe)) {
      setModaleTaxe(true);
    }
    else {
      setModaleTaxe(false);
    }

    setShowValidCommand(false);
    doShowRecapitulatif.current = true;
  };

  // Fermeture de la modale d'attente du paiement TPE
  const handleCloseModaleAttentePaiementTPE = (achat) => {
    // Fermeture de la modale
    setModaleAttentePaiementTPEVisible(false);

    if (!achat) {
      return;
    }

    switch (achat.statusPayzen) {
      case PAID:
        // Sauvegarde de l'achat dans le store
        storeAchat(achat);

        // Ouverture de la modale
        setTicketClientModaleVisible(true);
        break;

      default:
        // Information à l'utilisateur
        showError("Le paiement n'a pas abouti");
        break;
    }
  };

  // Paiement ko, sauvegarde de l'idAchat
  const handlePaymentError = (newAchat) => {
    doPostAchat(newAchat);
  };

  // Paiement ok
  const handlePaymentSuccess = (newAchat) => {
    // Désactivation de la modale
    setModalePayzenVisible(false);

    //  Envoi de l'idAchat au serveur
    doPostAchat(newAchat);

    // Sauvegarde de l'achat pour création du  ticket
    achat.current = {
      ...newAchat,
      type: PAYZEN
    };

    // Ouverture de la modale du ticket
    setTicketClientModaleVisible(true);
  };

  // Selection d'un détail achat
  const handleSelectDetailAchat = (detailAchat) => {
    let newDetail = {
      dateCreation: location.state.dateValidite ? moment(location.state.dateValidite).format() : moment().format(),
      idService: detailAchat.idService,
      notify: 0,
      quantite: detailAchat.quantite,
      secondes: detailAchat.secondes,
      serviceType: detailAchat.serviceType
    };
    let newDetailTaxe = null;


    //SH 2023.05.30 stockage de l'information derniers service si taxe sejour
    if (newDetail.serviceType === 4) {
      lastServicePark.current = true;
      qtParking.current = detailAchat.quantite;
    }
    else {
      lastServicePark.current = false;
    }


    //SH 2023.06.07 taxe sejour personne
    if (newDetail.serviceType === 10) {

      nbPersonne.current = detailAchat.quantite;


    }
    //fin SH

    if (detailAchat.serviceType === 4 && hasTax.current) {


      let findServiceTax = dataServices.current.findIndex(detail => detail.idServiceType === 10);
      if (findServiceTax !== -1) {
        let serviceTaxe = dataServices.current[findServiceTax];
        let tarifs = [...dataTarifs.current];
        let tarif = tarifs.find(tarif => tarif.idServiceType === serviceTaxe.idServiceType);
        newDetailTaxe = {
          dateCreation: location.state.dateValidite ? moment(location.state.dateValidite).format() : moment().format(),
          idService: serviceTaxe.idService,
          notify: 0,
          quantite: (tarif.idUniteType === 12) ? nbPersonne.current : detailAchat.quantite,
          secondes: detailAchat.secondes,
          serviceType: serviceTaxe.serviceType
        };
      }
    }
    //fin SH

    // Sauvegarde de la devise
    devise.current = detailAchat.devise;


    //Suppression de l'élément de détailAchat SH 2023.08.21
    if (!detailAchat.selected) {
      detailsAchat.current = detailsAchat.current.filter(item => item.quantite !== 0);
      hasParking.current = detailsAchat.current.filter(item => item.serviceType === 4 || item.serviceType === 9).length > 0;
      if (!hasParking.current) {
        detailsAchat.current = detailsAchat.current.filter(item => item.serviceType !== 10);
      }
      return;
    }
    //fin sh


    // Ajout dans le tableau
    if (!detailsAchat.current) {
      detailsAchat.current.push(newDetail);
      detailsAchat.current.push(newDetailTaxe);
      return;
    }

    // Recherche de l'élement
    let finded = detailsAchat.current.findIndex(detail => detail.idService === detailAchat.idService);

    if (finded === -1) {
      detailsAchat.current.push(newDetail);
    } else {
      detailsAchat.current[finded] = newDetail;
    }

    if (detailAchat.serviceType === 4 && hasTax.current) {
      // showError("Apres changement ajout taxe sejour");
      // return ;

      let findServiceTax = dataServices.current.findIndex(detail => detail.idServiceType === 10);
      if (findServiceTax === -1 && newDetailTaxe.quantite) {
        detailsAchat.current.push(newDetailTaxe);
      }
      else {
        if (newDetailTaxe.quantite) {
          detailsAchat.current[findServiceTax] = newDetailTaxe;
        }

      }
    }

    // Mise à jour de l'état de stationnement
    hasParking.current = detailsAchat.current.filter(item => item.serviceType === 4 || item.serviceType === 9).length > 0;
  };
  // #endregion

  // #region HOOK D'EFFET
  // Gestion du bouton back du header
  useEffect(() => {
    dispatch(setBackVisible(true));

    return () => dispatch(setBackVisible(false));
  }, [dispatch]);

  // Lancement de la requête
  useEffect(() => {
    if (!borne || !token) {
      return;
    }

    let params = [
      `idSite=${borne.id}`
    ];

    requestGetServices(params);
    requestGetTarifs(params);

    let zoneParams = [
      `idTerminal=${borne.id}`
    ];
    requestGetZone(zoneParams);
  }, [borne, token, requestGetServices, requestGetTarifs, requestGetZone]);

  // Récupération des services
  useEffect(() => {
    if (!dataReceivedServices) {
      return;
    }

    let d = [...dataReceivedServices];

    // Récupération du libellé
    d.forEach(service => {
      let serviceType = serviceTypes.data.find(type => type.idServiceType === service.idServiceType);
      service["serviceType"] = serviceType.libelle;
    });

    dataServices.current = d;
    countReqResult.current++;

    // Initialisation du state
    initializeData();
  }, [dataReceivedServices]);

  // Récupération des tarifs
  useEffect(() => {
    if (!dataReceivedTarifs) {
      return;
    }

    let d = [...dataReceivedTarifs];

    // Récupération du libellé de l'unité type
    d.forEach(tarif => {
      let uniteType = uniteTypes.data.find(type => type.idUniteType === tarif.idUniteType);
      tarif["libelleType"] = uniteType.libelleType;
      tarif["secondes"] = uniteType.secondes;
    });

    dataTarifs.current = dataReceivedTarifs;
    countReqResult.current++;

    // Initialisation du state
    initializeData();
  }, [dataReceivedTarifs]);


  // Récupération des places de parking
  useEffect(() => {
    if (!dataReceivedZone) {
      return;
    }

    let d = [...dataReceivedZone];

    // Calcul du nombre de place disponibles
    dataPlaces.current = d[0].nbPlace - d[0].placeOccupe

    countReqResult.current++;

    // Initialisation du state
    initializeData();
  }, [dataReceivedZone]);

  // Réception de la confirmation de la création de l'achat
  useEffect(() => {
    if (!dataUpdatedAchats) {
      return;
    }

    // Gestion du retour d'un achat TPE en attente
    if (dataUpdatedAchats.typePaiement === TPE) {
      // Enregistrement des informations necessaires à la création du ticket client
      typePaiement.current = dataUpdatedAchats.typePaiement;
      achatTPEId.current = dataUpdatedAchats.idAchat;

      // Ouverture de la modale d'attente du paiement
      setModaleAttentePaiementTPEVisible(true);
      return;
    }

    // Gestion du retour d'un achat Payzen valide
    if (dataUpdatedAchats.statusPayzen !== PAID) {
      return;
    }

    // Sauvegarde de l'achat dans le store
    storeAchat(dataUpdatedAchats);
  }, [storeAchat, dataUpdatedAchats]);
  // #endregion

  // #region INTERFACE
  if (!data) {
    return null;
  }

  return (
    <div className="Achat-Container">
      <div className="Achat-TitleContainer">
        {`${t("achat.borne")} ${borne.name}`}
      </div>
      <PerfectScrollbar>
        {doShowRecapitulatif.current && hasParking.current ?
          <div className="Achat-TextField">
            <TextField
              label={t("achat.immatriculation")}
              name="immatriculation"
              onChange={(e) => setImmatriculation(e.target.value)}
              outlined
              required
              value={immatriculation}

            />
          </div>
          :
          null
        }
        {doShowRecapitulatif.current && hasTax.current && hasParking.current ?
          <div className="Achat-TextField">
            {t("achat.MontantTaxes")}  {montantTaxes} €
          </div>
          :
          null
        }
        <List twoLine>
          {data.map((categorie) => {
            return (
              <AchatList
                categorie={categorie}
                doShowSelectedOnly={doShowRecapitulatif.current}
                doShowValidCommand={showValidCommand}
                key={categorie.id}
                nonInteractive={true}
                onChange={handleChange}
                onSelectDetailAchat={handleSelectDetailAchat}
              />
            )
          })}
        </List>
      </PerfectScrollbar>
      <div className="Achat-ButtonContainer">
        {
          showValidCommand
            ?
            hasTax.current && hasParking.current ?
              <Fab
                icon="shopping_cart"
                label={`${t("achat.validationTaxe") + " " + (montantTotal ? "(" + montantTotal + " €)" : "").trim()}`}
                onClick={handleClickValidCommand}
              />
              :
              <Fab
                icon="shopping_cart"
                label={`${t("achat.validation") + " " + (montantTotal ? "(" + montantTotal + " €)" : "").trim()}`}
                onClick={handleClickValidCommand}
              />
            :
            hasTax.current && hasParking.current ?
              <>
                <Fab
                  icon="undo"
                  label={t("achat.cancel")}
                  onClick={handleClickAnnuler}
                  style={{
                    marginRight: "10px"
                  }}
                >
                </Fab>

                <Fab
                  icon="payment"
                  label={`${t("achat.validationTaxe") + " " + (montantTotal ? "(" + montantTotal + " €)" : "").trim()}`}
                  onClick={() => handleClickPay(false)}
                >
                </Fab>
              </>
              :
              <>
                <Fab
                  icon="undo"
                  label={t("achat.cancel")}
                  onClick={handleClickAnnuler}
                  style={{
                    marginRight: "10px"
                  }}
                >
                </Fab>

                <Fab
                  icon="payment"
                  label={`${t("achat.validation") + " " + (montantTotal ? "(" + montantTotal + " €)" : "").trim()}`}
                  onClick={() => handleClickPay(false)}
                >
                </Fab>
              </>
        }
      </div>

      {
        <Modale
          onClose={() => { setModalePayzenVisible(false) }}
          title="Informations bancaires"
          visible={modalePayzenVisible}
          width="350px"
        >
          <PaymentForm
            borne={borne}
            devise={devise.current}
            montant={montantTotal * 100}
            onErrorPaid={(achat) => { handlePaymentError(achat) }}
            onSuccessPaid={(achat) => { handlePaymentSuccess(achat) }}
          />
        </Modale>
      }
      <ModaleTypePaiement
        onClick={handleClickChoixModePaiement}
        onClose={() => setModaleChoixPaiementVisible(false)}
        visible={modaleChoixPaiementVisible}
      />

      <TicketClientModale
        achat={achat.current}
        idAchatTPE={achatTPEId.current}
        onClose={() => { setTicketClientModaleVisible(false) }}
        typePaiement={typePaiement.current}
        visible={modaleTicketPayzenVisible}
      />
      <ModaleAttentePaiementTPE
        idAchat={achatTPEId.current}
        onClose={handleCloseModaleAttentePaiementTPE}
        visible={modaleAttentePaiementTPEVisible}
      />
      <ConfirmModal
        visible={modaleImmatriculation}
        onClose={() => setModaleImmatriculation(false)}
        onValid={() => [setModaleImmatriculation(false), handleClickPay(true)]}
        title={t("achat.confirmTitle")}
        children={t("achat.confirmMessage")}
        immatriculation={immatriculation}
        height={"250px"}
      />
      <ConfirmModal
        visible={modaleTaxe}
        onClose={() => setModaleTaxe(false)}
        onValid={() => [setModaleTaxe(false), handleClickValidCommand(true)]}
        title={t("achat.confirmTitleTaxe")}
        children={t("achat.confirmMessageTaxe")}
        immatriculation={`${t("achat.taxeSejour") + " : " + montantTaxes + " €"}`}
        info={(nbPersonne.current > 0) ? nbPersonne.current + " " + t("achat.personne") : " "}
        height={"250px"}
      />
      <Snackbar
        open={messageImmatriculation}
        onClose={() => setMessageImmatriculation(false)}
        message={t("achat.needImmatriculation")}
        dismissesOnAction
        action={
          <SnackbarAction
            label={t("achat.hideMessage")}
            onClick={() => setMessageImmatriculation(false)}
          />
        }
      />
    </div >
  );
  // #endregion
};
// #endregion

export { Achat };
