import { createSnackbarQueue } from '@rmwc/snackbar';

/**
* Utilitaire contenant des fonctions statiques pour l'affichage d'un toast
* @category Utils
* @hideconstructor
*/
class Toast {
  static Queue = createSnackbarQueue();
  /**
     * Fonction permettant d'envoyer un message à l'utilisateur
     * @param {string} message Le message à afficher
     * @param {string} aIcon L'icône à afficher
     * @param {string} aTitle Le titre à afficher
     * @returns {function} La notification
     */
  static showMessage(message, aIcon, aTitle) {
    let params = {
      actions: [
        {
          title: "Compris"
        }
      ],
      body: message,
      dismissesOnAction: true,
      icon: aIcon,
    };

    if (aTitle) {
      params = {
        ...params,
        title: aTitle
      };
    }

    return Toast.Queue.notify(params);
  }

  /**
     * Fonction permettant d'envoyer un message d'erreur à l'utilisateur
     * @param {string} message Le message à afficher
     * @returns {function} La notification
     */
  static showError(message) {
    return Toast.showMessage(message, "error", "Oups");
  }

  /**
    * Fonction permettant d'envoyer un message d'information à l'utilisateur
    * @param {string} message Le message à afficher
    * @returns {function} La notification
    */
  static showSuccess(message) {
    return Toast.showMessage(message, "check", null);
  }
}



export const Queue = Toast.Queue;
export const showError = Toast.showError;
export const showMessage = Toast.showMessage;
export const showSuccess = Toast.showSuccess;
