import * as Constants from "./constants";

/**
 * Actions pour la gestion des données renvoyées par le serveur
 * @category Data
 * @hideconstructor
 */
class DataResourceStoreAction {
  /**
   * Stockage de l'erreur pour une ressource
   * @param {string} name Nom de la ressource pour laquelle il faut vider les données
   * @param {string} error Le message d'erreur
   */
  static errorDataResource(name, error) {
    return {
      type: Constants.ERROR_DATA_RESOURCE,
      value: { name, error }
    };
  }

  /**
   * RAZ de toutes les données des ressources
   */
  static razAllDataResource() {
    return {
      type: Constants.RAZ_ALL_DATA_RESOURCE,
      value: null
    };
  }

  /**
   * RAZ des données pour une ressource
   * @param {string} name Nom de la ressource pour laquelle il faut vider les données
   */
  static razDataResource(name) {
    return {
      type: Constants.RAZ_DATA_RESOURCE,
      value: { name }
    };
  }

  /**
   * Récupération des données pour une ressource
   * @param {string} name Nom de la ressource pour laquelle il faut sauvegarder les données
   * @param {array/object} data Les données renvoyées par le serveur
   */
  static setDataResource(name, data) {
    return {
      type: Constants.SET_DATA_RESOURCE,
      value: { name, data }
    };
  }

  /**
   * Mise à jour des données pour une ressource
   * @param {string} name Nom de la ressource pour laquelle la mise à jour a été effectuée
   * @param {number} state Etat des données (0=CREATED, 1=UPDATED, 2=DELETED) pour la ressource _name_
   * @param {array/object} data Les données renvoyées par le serveur
   */
  static stateDataResource(name, state, data) {
    return {
      type: Constants.STATE_DATA_RESOURCE,
      value: { name, state, data }
    };
  }
}

// Exports
export const errorDataResource = DataResourceStoreAction.errorDataResource;
export const razAllDataResource = DataResourceStoreAction.razAllDataResource;
export const razDataResource = DataResourceStoreAction.razDataResource;
export const setDataResource = DataResourceStoreAction.setDataResource;
export const stateDataResource = DataResourceStoreAction.stateDataResource;
