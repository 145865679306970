import PropTypes from "prop-types";
import React from "react";
import Modal from "react-awesome-modal";
import { Fab } from "@rmwc/fab";

import "@rmwc/fab/styles";
import "./ConfirmModal.css";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher une modale
 * @class
 * @category ConfirmModal
 * @param {ConfirmModal.propTypes} props
 */
function ConfirmModal(props) {
  const {
    children,
    height,
    immatriculation,
    onClose,
    onValid,
    title,
    visible,
    info,
    width
  } = props;

  return (
    <Modal
      effect="fadeInUp"
      height={height}
      onClickAway={onClose}
      visible={visible}
      width={width}
    >
      <div className="ConfirmModal-Container">
        <div className="ConfirmModal-Title-Container">
          {title}
        </div>
        <div className="ConfirmModal-Message-Container">
          <p>
            {children}
          </p>
          <p className="ConfirmModal-Message-Immatriculation">
            {immatriculation}
          </p>
          <p className="ConfirmModal-Message-Immatriculation">
            {info}
          </p>
        </div>
        <div className="ConfirmModal-Button-Container">
          {onClose &&
            <Fab
              icon="close"
              mini
              onClick={onClose}
            />
          }
          <Fab
            icon="check"
            mini
            onClick={onValid}
          />
        </div>
      </div>
    </Modal >
  );
}
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link ConfirmModal}
 * @typedef {Object} ConfirmModal.propTypes
 * @property {object} children Composant enfant
 * @property {string} [immatriculation=""] Immatriculation du véhicule
 *  @property {string} [info=""] Immatriculation du véhicule
 * @property {string} [height="450"] Hauteur de la modale
 * @property {function} [onClose=null] Fonction de callback de fermeture
 * @property {string} title Titre de la modale
 * @property {boolean} visible true pour afficher la fenêtre
 * @property {string} [width="200"] largeur de la modale
 */
ConfirmModal.propTypes = {
  children: PropTypes.object.isRequired,
  height: PropTypes.string,
  immatriculation: PropTypes.string,
  info : PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  width: PropTypes.string,
};

ConfirmModal.defaultProps = {
  height: "450",
  immatriculation: "",
  info: "",
  onClose: null,
  width: "300px",
};
// #endregion

export { ConfirmModal };
