// ** Actions
// Référentiel
export const SET_DATA_SERVICE_TYPES = "SET_DATA_SERVICE_TYPES";
export const SET_DATA_UNITE_TYPES = "SET_DATA_UNITE_TYPES";
export const RESET_DATA_REFERENTIEL = "RESET_DATA_REFERENTIEL";

// Statut initial
export const INITIAL_STATE = {
  dataTypes: {},
  dataUniteTypes: {},
};
