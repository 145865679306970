import * as Constants from "./constants";

/**
 * Reducer de gestion du composant de chargement dans le store
 * @private
 * @param {object} state Le state de l'application
 * @param {object} action L'action contenant le type et la valeur
 */
function manageLoading(state = Constants.INITIAL_STATE, action) {
  let nextState;
  switch (action.type) {
    case Constants.RESET_LOADING:
      // On reset l'affichage du composant de chargement
      nextState = {
        ...state,
        loading: {
          ...state.loading,
          showLoading: 0
        }
      };
      return nextState || state;

    case Constants.SHOW_LOADING:
      // On affiche/cache le composant de chargement
      let value = state.loading.showLoading;
      if (action.value) {
        value += 1;
      } else {
        value -= 1;
        if (value < 0) {
          value = 0;
        }
      }
      nextState = {
        ...state,
        loading: {
          ...state.loading,
          showLoading: value
        }
      };
      return nextState || state;

    default:
      return state;
  }
}

export { manageLoading };
