import { manageGeolocationBlocked } from "./Geolocation/reducers"
import { manageAchat } from "Achat/Store/reducers";
import { manageToken } from "App/Authentification/Store/reducers";
import { manageHeader } from "App/Header/Store/reducers";
import { manageLoading } from "App/Loading/Store/reducers";
import { createStore } from "redux";
import { persistCombineReducers, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { manageReferentiel } from "Referentiel/Store/reducers";
import { manageDataResource } from "Request/Store/reducers";

const rootPersistConfig = {
  key: "alt-ekip-store",
  storage: storage
};

export const store = createStore(
  persistCombineReducers(rootPersistConfig, {
    manageAchat,
    manageDataResource,
    manageGeolocationBlocked,
    manageHeader,
    manageLoading,
    manageReferentiel,
    manageToken
  })
);

export const persistor = persistStore(store);
