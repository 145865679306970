import { TimeSelector } from "./TimeSelector";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Fab } from "@rmwc/fab";
import {
  ListDivider,
  ListItem,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemText,
  ListItemSecondaryText
} from "@rmwc/list";

import "@rmwc/list/styles";
import "@rmwc/fab/styles";
import { QuantiteSelector } from "./QuantiteSelector";
import { PersonneSelector } from "./PersonneSelector";//SH 2023.06.07

// #region CONSTANTES
const PRICE = "price";
const QUANTITE = "quantite";
const SELECTED = "selected";
const PERSONNE ="personne" ;
// #endregion

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher un item de catégorie d'une borne
 * @category Achat
 * @param {AchatListItem.propTypes} props Les propriétés du composant
 */
function AchatListItem(props) {
  //#region INITIALISATION
  // Récupération des props
  const {
    doShowSelectedOnly,
    doShowValidCommand,
    lock,
    onChange,
    onSelectDetailAchat,
    onSelectItem,
   // serviceTaxe ,
    service,
  } = props;

  // Initialisation du state
  const [data, setData] = useState({});

  // Initialisation des props de navigation
  const location = useLocation();

  // Traduction i18n
  const { t } = useTranslation();

  // Initialisation des références
  const isActif = useRef(location.state.servicesActifs.includes(service.idService)); // service actif dans "ma distribution"
  const hasPlaces = useRef(service.places && service.places > 0); // calcul du nombre de places disponible

  // #endregion

  // #region EVENTS
  // Modification du state
  const handleChange = (name, value) => {
    let d = { ...data };

    switch (name) {
      case PRICE:
        d[QUANTITE] = Math.round(value / service.tarif.secondes);

        let newPriceTime = (Math.round(value / service.tarif.secondes)) * service.tarif.prix;

        d[name] = newPriceTime;

        // Changement du montant total
        if (!data.price) {
          onChange(newPriceTime);
        } else {
          onChange(newPriceTime - data.price);
        }

        // Changement de l'état de l'item si aucun montant
        if (!value) {
          d[SELECTED] = false;
          onSelectItem(false);
        }
        break;

      case QUANTITE:
        d[QUANTITE] = value;

        let newPriceQte = value * service.tarif.prix;

        d[PRICE] = newPriceQte;
        // Changement du montant total
          if (!data.price) {
            onChange(newPriceQte);
          } else {
            onChange(newPriceQte - data.price);
          }

        // Changement de l'état de l'item si aucun montant
        if (!value) {
          d[SELECTED] = false;
          onSelectItem(false);
        }
        break;

      case SELECTED:
        d[name] = value;
        onSelectItem(value);
        break;

      case PERSONNE:
        d[QUANTITE] = value;
        let newPricePersonne = value * service.tarif.prix;
        d[PRICE] = newPricePersonne;
        onChange(0);
        break;

      default:
        break;
    }

    onSelectDetailAchat({
      ...d,
      devise: service.tarif.devise,
      idService: service.idService,
      secondes: service.tarif.secondes,
      serviceType: service.idServiceType
    });

    // Modification du state
    setData(d);
  };

  // Vérification de la disponibilité en cas de rachat
  const handleDisponibilite = () => {
    if (isActif.current) {
      return true;
    } else if (!service.disponible) {
      if (service.idServiceType === 4) {
        return hasPlaces.current;
      }
      return true;
    }
    return false;
  };
  // #endregion

  // #region HOOK D'EFFET
  // si dépassement de stationnement, initialisation
  useEffect(() => {
    if (!service.depassement || service.depassement < 1) {
      return;
    }

    // handleChange(PRICE, service.depassement * service.tarif.secondes);
    handleChange(SELECTED, true);
  }, []);

  // #endregion

  //#region INTERFACE
  return (
    <>
      {(doShowSelectedOnly && !data.selected) ?
        null
        :
        <>
          <ListItem
            key={service.idService}
            ripple={false}
            selected={false}
            style={{ height: "85px" }}
          >
            <ListItemText>
              <ListItemPrimaryText>{service.nom}</ListItemPrimaryText>
              {service.tarif &&
                <ListItemSecondaryText>{`${service.tarif.prix} ${service.tarif.deviseSymbole} / ${service.tarif.libelleType}`}</ListItemSecondaryText>

              }
              {service.places && service.places > 0 &&
                <ListItemSecondaryText>{service.places} {t("achat.places")}</ListItemSecondaryText>
              }
            </ListItemText>
            <ListItemMeta>
              {((lock && !data.selected) || !service.tarif) ?
                null
                :
                (data.selected ?
                  service.tarif.idUniteType === 6 ?
                    <QuantiteSelector
                      doShowButtons={!doShowSelectedOnly}
                      onChange={(value) => { handleChange(QUANTITE, value) }}
                    />
                    :
                    <TimeSelector
                      depassement={service.depassement * service.tarif.secondes}
                      doShowButtons={!doShowSelectedOnly}
                      validCommand={doShowValidCommand}
                      onChange={(value) => { handleChange(PRICE, value) }}
                      step={service.tarif.secondes}
                      value={data.quantite * service.tarif.secondes}
                    />
                    :

                  ((service.idServiceType === 10) && (service.tarif.idUniteType === 13)) ?
                    <div>{t("achat.serviceTaxe")}</div>
                    :
                    service.idServiceType === 10 ?
                      <PersonneSelector
                      doShowButtons={!doShowSelectedOnly}
                      onChange={(value) => { handleChange(PERSONNE, value) }}
                      />
                    :
                    handleDisponibilite()  ?
                      <Fab
                        icon="add_shopping_cart"
                        mini
                        onClick={() => handleChange(SELECTED, true)}
                      />
                      :
                      <div>{t("achat.serviceOut")}</div>



                )
              }
            </ListItemMeta>
          </ListItem>
          <ListDivider />
        </>
      }
    </>
  )
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link AchatListItem}
 * @typedef {Object} AchatListItem.propTypes
 * @property {number} depassementValue Valeur du dépassement de stationnement à racheter
 * @property {boolean} doShowSelectedOnly True pour n'afficher que si l'élement est selectionné, false sinon
 * @property {boolean} lock True si un item est déjà selectionné, false sinon
 * @property {function} onChange Fonction callback sur changement du prix total
 * @property {function} onSelectDetailAchat Fonction callback sur sélection d'un service
 * @property {function} onSelectItem Fonction callback sur selection d'un item
 * @property {object} service L'item de la catégorie
 */
AchatListItem.propTypes = {
  depassementValue: PropTypes.number,
  doShowSelectedOnly: PropTypes.bool.isRequired,
  lock: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectDetailAchat: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  serviceTaxe : PropTypes.object,
  service: PropTypes.object.isRequired
};

AchatListItem.defaultProps = {
  depassementValue: 0
}
// #endregion

export { AchatListItem };

