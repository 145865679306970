/**
 * Sélecteurs pour la gestion du token
 * @category Store
 * @hideconstructor
 */
class TokenStoreSelector {
  /**
   * Sélection de l'objet contenant les informations du token
   * @param {object} state state de l'application
   * @returns {TokenObject} Informations de session
   */
  static selectToken(state) {
    return state.manageToken.token;
  }

  /**
   * Sélection de la date et heure de la récupération du token
   * @param {object} state state de l'application
   * @returns {date} Variable contenant la date et heure de la récupération du token
   */
  static selectTokenDate(state) {
    return TokenStoreSelector.selectToken(state).date;
  }

  /**
   * Sélection du token
   * @param {object} state state de l'application
   * @returns {string} Variable contenant le token
   */
  static selectTokenValue(state) {
    return TokenStoreSelector.selectToken(state).token;
  }
}

export const selectToken = TokenStoreSelector.selectToken;
export const selectTokenDate = TokenStoreSelector.selectTokenDate;
export const selectTokenValue = TokenStoreSelector.selectTokenValue;
