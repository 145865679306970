import { ResetStore } from "./ResetStore";
import { persistor, store } from "./Store/configureStore";
import { Accueil } from "Accueil/Components/Accueil";
import { Achat } from "Achat/Components/Achat";
import { Authentification } from "App/Authentification/Components/Authentification";
import { Header } from "App/Header/Components/Header";
import { Loading } from "App/Loading/Components/Loading";
import { Queue } from 'App/Toast/Toast';
import { Paiement } from "Paiement/Paiement";
import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";
import { ReferentielLoader } from "Referentiel/Components/ReferentielLoader";
import { SnackbarQueue } from "@rmwc/snackbar";
import { ThemeProvider } from "@rmwc/theme";

import "@rmwc/snackbar/styles";
import "./App.css";

/**
 * Point d'entrée de l'application
 * @private
 */
function App() {
  // #region INTERFACE
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <ThemeProvider
            options={{
              primary: "#000000",
              primaryBg: "#35A7D7",
              secondary: "#000000",
              onPrimary: "#000000",
              onSecondary: "#35A7D7"
            }}
          >
            <div className="App-Container">
              <Header />
              <div className="App-Content">
                <Switch>
                  <Route path="/paiement">
                    <Paiement />
                  </Route>
                  <Route path="/achat">
                    <Achat />
                  </Route>
                  <Route path="/">
                    <Accueil />
                  </Route>
                </Switch>
              </div>
              <SnackbarQueue messages={Queue.messages} timeout={-1} />
            </div>
            <Authentification />
            <Loading />
            <ReferentielLoader />
          </ThemeProvider>
        </Router>
        <ResetStore />
      </PersistGate>
    </Provider>
  );
  // #endregion
}

export default App;
