import * as Constants from "./constants";

/**
 * Actions pour la gestion de la géolocalisation
 * @category Store
 * @hideconstructor
 */
class GeolocationStoreAction {
  /**
   * Réinitialisation de l'indicateur de géolocalisation bloquée
   */
  static resetGeolocationBlocked() {
    return {
      type: Constants.RESET_GEOLOCATION_BLOCKED
    };
  }

  /**
   * Mise à jour de l'indicateur de géolocalisation bloquée
   * @param {string} achat Achat
   */
  static setGeolocationBlocked(achat) {
    return {
      type: Constants.SET_GEOLOCATION_BLOCKED,
      value: achat
    };
  }
}

// Exports
export const resetGeolocationBlocked = GeolocationStoreAction.resetGeolocationBlocked;
export const setGeolocationBlocked = GeolocationStoreAction.setGeolocationBlocked;
