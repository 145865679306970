import { API_KEY, ZOOM_COUNTRY } from "../Constants";
import PropTypes from "prop-types";
import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import '@rmwc/icon/styles';

// #region FONCTION COMPOSANT
/**
 * Un sous-composant de {@link Map} qui gère les HOC
 * @class
 * @category Map
 */
const MyMapComponent = withScriptjs(withGoogleMap((props) => {
  return (
    <GoogleMap
      {...props}
    >
      {props.isMarkerShown &&
        <Marker position={props.center ? props.center : props.defaultCenter} />
      }
      {props.markers &&
        props.markers.map((item) => {
          return (
            <Marker
              key={item.id}
              position={item.location}
              icon={{
                ...item.icon,
                anchor: new window.google.maps.Point(15, 20)
              }}
              onClick={() => { props.onClick(item) }}
            />
          )
        }
        )}
    </GoogleMap>
  );
}));

/**
 * Une carte Google Map
 * @class
 * @category Map
 * @param {Map.propTypes} props Les propriétés du composant
 */
function Map(props) {
  // #region INITIALISATION
  const {
    center,
    defaultCenter,
    defaultZoom,
    isMarkerShown,
    markers,
    onClick,
    zoom,
  } = props;
  // #endregion

  // #region INTERFACE
  return (
    <MyMapComponent
      zoom={zoom ? zoom : defaultZoom}
      center={center ? center : defaultCenter}
      isMarkerShown={isMarkerShown}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ bottom: "80px", left: "0px", position: "absolute", right: "0px", top: "0px", }} />}
      mapElement={<div style={{ height: `100%` }} />}
      markers={markers}
      onClick={onClick}
    />
  );
  //#endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link Map}
 * @typedef {Object} Map.propTypes
 * @property {object} [center=null] Le placeholder du champ
 * @property {object} [defaultCenter={ lat: 46.7235971, lng: 2.4992264 }] Le placeholder du champ
 * @property {number} [defaultZoom=5] Le placeholder du champ
 * @property {boolean} [isMarkerShown=false] Le placeholder du champ
 * @property {array} [markers=null] Liste des markers à afficher
 * @property {number} [zoom=null] Le nom du champ
 */
Map.propTypes = {
  center: PropTypes.object,
  defaultCenter: PropTypes.object,
  defaultZoom: PropTypes.number,
  isMarkerShown: PropTypes.bool,
  markers: PropTypes.array,
  zoom: PropTypes.number,
};

Map.defaultProps = {
  center: null,
  defaultCenter: { lat: 46.7235971, lng: 2.4992264 }, // Coordonnées de Saint-Amand-Montrond (approximativement le centre de la France)
  defaultZoom: ZOOM_COUNTRY, // Au niveau de zoom 5, on voit la France entière
  isMarkerShown: false,
  markers: null,
  zoom: null,
};
// #endregion

export { Map };
