import * as Constants from "./constants";

/**
 * Actions pour la gestion de l'achat
 * @category Store
 * @hideconstructor
 */
class AchatStoreAction {
  /**
   * Réinitialisation de l'achat
   */
  static resetAchat() {
    return {
      type: Constants.RESET_ACHAT
    };
  }

  /**
   * Mise à jour de l'achat
   * @param {string} achat Achat
   */
  static setAchat(achat) {
    return {
      type: Constants.SET_ACHAT,
      value: achat
    };
  }

  /**
   * Mise à jour de l'achat
   * @param {string} distribution Distribution
   */
  static setDistribution(distribution) {
    return {
      type: Constants.SET_DISTRIBUTION,
      value: distribution
    };
  }
}

// Exports
export const resetAchat = AchatStoreAction.resetAchat;
export const setAchat = AchatStoreAction.setAchat;
export const setDistribution = AchatStoreAction.setDistribution;
