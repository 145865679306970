import { selectShowLoading } from "../Store/selectors";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CircularProgress } from "@rmwc/circular-progress";

import "@rmwc/circular-progress/styles";
import "./Loading.css";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher un Loader
 * @category Loading
 * @param {Loading.propTypes} props Les propriétés du composant
 */
function Loading() {
  // #region INITIALISATION
  // Récupération des sélectors
  const showLoading = useSelector(state => selectShowLoading(state));

  // Traduction i18n
  const { t } = useTranslation();
  // #endregion

  //#region INTERFACE
  if (showLoading === 0) {
    return null;
  }

  return (
    <div className="Loading-Container">
      <CircularProgress size="xlarge" style={{ color: "#35A7D7" }} />
      <p className="Loading-Text">{t("loading.text")}</p>
    </div>
  );
  // #endregion
};
// #endregion

export { Loading };
