import * as Constants from "./constants";
import moment from "moment";

/**
 * Reducer de gestion de l'objet achat dans le store
 * @private
 * @param {object} state Le state de l'application
 * @param {object} action L'action contenant le type et la valeur
 */
function manageAchat(state = Constants.INITIAL_STATE, action) {
  let nextState;
  switch (action.type) {
    case Constants.RESET_ACHAT:
      // Mise à jour de l'achat
      nextState = {
        ...state,
        achats: []
      };
      return nextState || state;

    case Constants.SET_ACHAT:
      // Test de la validité des achats dans le store
      let newAchats = state.achats;

      if (action.value) {
        newAchats.push(action.value);
      }

      // Mise à jour du state
      nextState = {
        ...state,
        achats: [
          ...newAchats
        ],
      };
      return nextState || state;

    case Constants.SET_DISTRIBUTION:
      // Test de la validité des achats dans le store
      let newDistribution = [];
      let stateTest = state.achats

      // state.achats.forEach(achat => {
      stateTest.forEach(achat => {
        // filtre de la distribution à modifier
        const distrib = action.value.filter(item => item.idAchat === achat.id);

        // Si stationnement, vérification de la présence
        if (achat.immatriculation) {
          achat.presence = distrib[0].presence ?? false;
        }

        // Si date pas dépassé ou si présent sur l'aire, on ajoute l'achat au tableau
        if (moment(achat.date).diff(moment()) > 0 || achat.presence) {
          newDistribution.push(achat);
        }
      });

      // Mise à jour du state
      nextState = {
        ...state,
        achats: [
          ...newDistribution
        ],
      };
      return nextState || state

    default:
      return state;
  }
}

export { manageAchat };
