import {
  errorDataResource,
  razAllDataResource,
  razDataResource,
  setDataResource,
  stateDataResource
} from "../Store/actions";
import {
  DATA_NO_STATE,
  DATA_CREATED,
  DATA_DELETED,
  DATA_UPDATED
} from "../Store/constants";
import {
  createResource,
  deleteResource,
  getResource,
  getResourceWithParams,
  getResources,
  getResourcesWithParams,
  updateResource,
  DELETE,
  GET,
  PATCH,
  POST,
  PUT,
  UNAUTHORIZED
} from "./RequestUtils";
import { selectTokenValue } from "App/Authentification/Store/selectors";
import { hideLoading, showLoading } from "App/Loading/Store/actions";
import { showError } from "App/Toast/Toast";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// #region CONSTANTES
export const GET_LIST = 0;
export const GET_LIST_WITH_PARAMS = 1;
export const GET_SINGLE = 2;
export const GET_SINGLE_WITH_PARAMS = 3;
const updateCommand = [POST, PATCH, DELETE];
// #endregion

// #region HOOK
/**
 * HOOK permettant de gérer les requêtes de type REST avec des données en JSON
 * @class
 * @category Data
 * @param {useJsonRequest.propTypes} props Les propriétés du composant
 */
function useJsonRequest(props) {
  // #region INITIALISATION
  // Récupération et initialisation (les propTypes ne font rien pour les HOOKs) des props
  const {
    command,
    getMode = null,
    manageToken = true,
    razScreenFunc = null,
    resource,
    silent = false,
    timeout = 30000,
    tokenObligatory = true,
    url
  } = props;

  // Récupération des sélecteurs
  const token = useSelector((state) => selectTokenValue(state));

  // Récupération du dispatch
  const dispatch = useDispatch();
  // #endregion

  // #region UTILS
  /**
   * Gestion du lancement du chargement
   * @private
   * @returns true si la requête peut être envoyée, false sinon
   */
  function beginLoading() {
    if (!silent) {
      // Affichage du composant de chargement
      dispatch(showLoading());
    }
  };

  /**
   * Gestion de la fin de chargement
   * @private
   */
  function endLoading() {
    if (silent) {
      return;
    }

    // On cache le composant de chargement
    dispatch(hideLoading());
  };

  /**
   * Nom de la ressource pour la sauvegarde dans le store
   * @param {string} suffixResource Suffixe de la resource afin de différencier le résultat dans le store si le nom de la ressource est identique pour plusieurs ws ayanyt des différences dans les paramètres de l'url
   * @private
   */
  function getStoreResourceName(suffixResource) {
    let storeResourceName = resource;
    if (suffixResource) {
      storeResourceName = storeResourceName.concat("-", suffixResource);
    }
    return storeResourceName;
  };
  // #endregion

  // #region CALLBACK
  /**
   * Callback lors du succès d'une requête
   * @private
   * @param {objet} result Résultat de la requête
   */
  function onSuccess(result, suffixResource) {
    // Détermination du nom de l'objet dans le store
    let storeResourceName = getStoreResourceName(suffixResource);

    // Notification des données reçues
    if (result) {
      dispatch(setDataResource(storeResourceName, result));
    }

    // Notification du résultat
    switch (command) {
      case DELETE:
        dispatch(stateDataResource(storeResourceName, DATA_DELETED, result));
        break;

      case GET:
        if (result) {
          dispatch(setDataResource(storeResourceName, result));
        }
        break;

      case POST:
        dispatch(stateDataResource(storeResourceName, DATA_CREATED, result));
        break;

      case PUT:
        dispatch(stateDataResource(storeResourceName, DATA_UPDATED, result));
        break;

      case PATCH:
        dispatch(stateDataResource(storeResourceName, DATA_UPDATED, result));
        break;

      default:
        break;
    }

    if (updateCommand.indexOf(command) !== -1) {
      // RAZ du statut après 500ms
      setTimeout(() => {
        dispatch(stateDataResource(storeResourceName, DATA_NO_STATE, null));
      }, 500);
    }

    // RAZ des data après 500ms
    setTimeout(() => {
      dispatch(razDataResource(storeResourceName));
    }, 500);

    // Arrêt du chargement
    endLoading();
  };

  /**
   * Callback lors de l'échec d'une requête
   * @private
   * @param {objet} error Contient les informations sur l'erreur
   */
  function onError(error, suffixResource) {
    // Détermination du nom de l'objet dans le store
    let storeResourceName = getStoreResourceName(suffixResource);

    // RAZ des anciennes données
    dispatch(razDataResource(storeResourceName));

    if (error.status === UNAUTHORIZED) {
      // Dispatch recup Token à true pour forcer le composant a recharger un token
      // RAZ du store si non autorisé
      dispatch(razAllDataResource());
    }

    // Affichage d'un message si error est une chaine
    if (typeof error.error === "string" && !silent) {
      showError("Quelque chose s'est mal passé, veuillez réessayer ultérieurement");
    }

    // Arrêt du chargement
    endLoading();

    // Notification de l'erreur
    dispatch(errorDataResource(storeResourceName, error));
    // RAZ de l'erreur après 500ms
    setTimeout(() => {
      dispatch(errorDataResource(storeResourceName, null));
    }, 500);
  };
  // #endregion

  // #region FONCTION DE RETOUR => la requête a exécutée
  // Détermination de la fonction de retour
  let result = () => { };
  // Gestion du token ?
  let tokenValue = (manageToken) ? token : null;

  switch (command) {
    case GET:
      switch (getMode) {
        case GET_LIST:
          // Récupération d'une liste de ressource
          result = (suffixResource = "") => {
            // Si pas de token mais token obligatoire, on sort
            if (tokenObligatory && !token) {
              return;
            }

            // RAZ de l'écran avant récupération des données
            if (razScreenFunc) {
              razScreenFunc();
            }

            // Début du chargement
            beginLoading();

            // RAZ des anciennes données
            dispatch(razDataResource(getStoreResourceName(suffixResource)));

            // Requête de récupération de ressources
            getResources(url, tokenValue, resource, timeout)
              .then((result) => onSuccess(result, suffixResource))
              .catch((error) => onError(error, suffixResource));
          };
          break;

        case GET_LIST_WITH_PARAMS:
          // Récupération d'une liste de ressource avec paramètres
          result = (params, suffixResource = "") => { // params est un tableau de chaine de la forme [cle1=val1, cle2=val2, ...]
            // Si pas de token mais token obligatoire, on sort
            if (tokenObligatory && !token) {
              return;
            }

            // RAZ de l'écran avant récupération des données
            if (razScreenFunc) {
              razScreenFunc();
            }

            // Début du chargement
            beginLoading();

            // RAZ des anciennes données
            dispatch(razDataResource(getStoreResourceName(suffixResource)));

            // Requête de récupération de ressources avec paramètres
            getResourcesWithParams(url, tokenValue, resource, params, timeout)
              .then((result) => onSuccess(result, suffixResource))
              .catch((error) => onError(error, suffixResource));
          };
          break;

        case GET_SINGLE:
          // Récupération d'une ressource
          result = (id, suffixResource = "") => {
            // Si pas de token mais token obligatoire, on sort
            if (tokenObligatory && !token) {
              return;
            }

            // RAZ de l'écran avant récupération des données
            if (razScreenFunc) {
              razScreenFunc();
            }

            // Début du chargement
            beginLoading();

            // RAZ des anciennes données
            dispatch(razDataResource(getStoreResourceName(suffixResource)));

            // Requête de récupération d'une ressource
            getResource(url, tokenValue, resource, id, timeout)
              .then((result) => onSuccess(result, suffixResource))
              .catch((error) => onError(error, suffixResource));
          };
          break;

        case GET_SINGLE_WITH_PARAMS:
          // Récupération d'une ressource
          result = (id, params, suffixResource = "") => {
            // Si pas de token mais token obligatoire, on sort
            if (tokenObligatory && !token) {
              return;
            }

            // RAZ de l'écran avant récupération des données
            if (razScreenFunc) {
              razScreenFunc();
            }

            // Début du chargement
            beginLoading();

            // RAZ des anciennes données
            dispatch(razDataResource(getStoreResourceName(suffixResource)));

            // Requête de récupération d'une ressource
            getResourceWithParams(url, tokenValue, resource, id, params, timeout)
              .then((result) => onSuccess(result, suffixResource))
              .catch((error) => onError(error, suffixResource));
          };
          break;

        default:
          break;
      }
      break;

    case POST:
      result = (data, suffixResource = "") => {
        // Si pas de token mais token obligatoire, on sort
        if (tokenObligatory && !token) {
          return;
        }
        // Début du chargement
        beginLoading();

        // Requête de création d'une ressource
        createResource(url, token, resource, data)
          .then((result) => onSuccess(result, suffixResource))
          .catch((error) => onError(error, suffixResource));
      };
      break;

    case PATCH:
      result = (id, data, suffixResource = "") => {
        // Si pas de token mais token obligatoire, on sort
        if (tokenObligatory && !token) {
          return;
        }
        // Début du chargement
        beginLoading();

        // Requête de mise à jours d'une ressource
        updateResource(url, tokenValue, resource, id, data)
          .then((result) => onSuccess(result, suffixResource))
          .catch((error) => onError(error, suffixResource));
      };
      break;

    case DELETE:
      result = (id, suffixResource = "") => {
        // Si pas de token mais token obligatoire, on sort
        if (tokenObligatory && !token) {
          return;
        }

        // Début du chargement
        beginLoading();

        // Requête de suppression d'une ressource
        deleteResource(url, tokenValue, resource, id)
          .then(() => onSuccess(null, suffixResource))
          .catch((error) => onError(error, suffixResource));
      };
      break;

    default:
      break;
  }

  return useCallback(result, [token]);
  // #endregion
}
// #endregion

// #region PROPRIETES
/**
  * Type des propriétés
  * @typedef {Object} useJsonRequest.propTypes
  * @property {string} url URL de la requête
  * @property {string} resource Nom de la resource pour la requête
  * @property {string} command Commade HTTP (GET, POST, PATCH ou DELETE)
  * @property {number} [getMode=null] Mode de la requête de type GET : 0=requête sans paramètres et sans id, 1=requête avec paramètres, 2=requête avec id
  * @property {boolean} [manageToken=true] false pour ne pas gérer le header Authorization dans les requêtes, true sinon
  * @property {function} [razScreenFunc=null] Fonction permettant de RAZ l'écran avant l'exécution de la requête
  * @property {boolean} [silent=false] true pour ne pas gérer le composant de chargement, false sinon
  * @property {boolean} [tokenObligatory=true] true si le token est obligatoire, false sinon
  */
useJsonRequest.propTypes = {
  command: PropTypes.string.isRequired,
  getMode: PropTypes.number,
  manageToken: PropTypes.bool,
  razScreenFunc: PropTypes.func,
  resource: PropTypes.string.isRequired,
  silent: PropTypes.bool,
  tokenObligatory: PropTypes.bool,
  url: PropTypes.string.isRequired,
};

/*
  Valeurs par défaut des propriétés
*/
useJsonRequest.defaultProps = {
  getMode: null,
  manageToken: true,
  razScreenFunc: null,
  silent: false,
  tokenObligatory: true
};
// #endregion

export { useJsonRequest };
