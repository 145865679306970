import * as Constants from "./constants";

/**
 * Actions pour la gestion du bouton back
 * @category Header
 * @hideconstructor
 */
class HeaderStoreAction {
  /**
   * Mise à jour du de la visibilité du bouton back dans le header
   * @param {boolean} visible True pour afficher, false sinon
   */
  static setBackVisible(visible) {
    return {
      type: Constants.SET_BACK_VISIBLE,
      value: visible
    };
  }
}

export const setBackVisible = HeaderStoreAction.setBackVisible;
