import * as Constants from "./constants";
import md5 from "js-md5";

/**
 * Gestion des données locales de l'application pour les référentiels (cache)
 * @private
 * @param {object} state : le state de l'application
 * @param {object} action : l'action contenant le type et la valeur
 */
function manageReferentiel(state = Constants.INITIAL_STATE, action) {
  let nextState;
  switch (action.type) {
    case Constants.SET_DATA_SERVICE_TYPES:
      // Mise à jour du référenciel des types de service
      nextState = {
        ...state,
        dataTypes: {
          data: action.value,
          hash: md5(action.value)
        }
      };
      return nextState || state;

    case Constants.SET_DATA_UNITE_TYPES:
      // Mise à jour du référenciel des types d'unité
      nextState = {
        ...state,
        dataUniteTypes: {
          data: action.value,
          hash: md5(action.value)
        }
      };
      return nextState || state;

    case Constants.RESET_DATA_REFERENTIEL:
      // RAZ de la liste des référentiels
      nextState = {
        ...state,
        dataTypes: {},
        dataTarifs: {},
        dataUniteTypes: {},
      };
      return nextState || state;

    default:
      return state;
  }
}

export { manageReferentiel };
