// Actions
export const RESET_GEOLOCATION_BLOCKED = "RESET_GEOLOCATION_BLOCKED";
export const SET_GEOLOCATION_BLOCKED = "SET_GEOLOCATION_BLOCKED";

// Statut initial
export const INITIAL_STATE = {
  /**
   * Boolean isGeolocationBlocked sauvegardé dans le store
   * @category Store
   * @typedef {boolean} GeolocationBoolean
   * @property {boolean} [isGeolocationBlocked=false] True si la géolocalisation a été bloquée par l'utilisateur, false sinon
   */
  isGeolocationBlocked: false
};
