// Actions
export const RESET_ACHAT = "RESET_ACHAT";
export const SET_ACHAT = "SET_ACHAT";
export const SET_DISTRIBUTION = "SET_DISTRIBUTION";

// Statut initial
export const INITIAL_STATE = {
  /**
   * Objet Achat sauvegardé dans le store
   * @category Store
   * @typedef {object} AchatObject
   * @property {string} [achat=[]] Achat
   */
  achats: []
};
