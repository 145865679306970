import { RESSOURCE_ACHATS } from "../API/AchatApi";
import { URL_BASE } from "App/Constants";
import { Modale } from "App/Modale/Components/Modale";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GET } from "Request/Components/RequestUtils";
import { useJsonRequest, GET_SINGLE } from "Request/Components/useJsonRequest";
import { selectDataResourceReceived } from "Request/Store/selectors";
import { Fab } from "@rmwc/fab";

import "@rmwc/fab/styles";
import "./ModaleAttentePaiementTPE.css";

// #region CONSTANTES
const PAYMENT_DELAY = 70000; // 1 minute 10
// #endregion

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher le choix du mode de paiement
 * @category Achat
 */
function ModaleAttentePaiementTPE(props) {
  //#region INITIALISATION
  // Récupération des props
  const {
    idAchat,
    onClose,
    visible
  } = props;

  // Traduction i18n
  const { t } = useTranslation();

  // Initialisation des selecteurs
  const dataReceived = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_ACHATS)); // Données issues de la requête

  // Initialisation des références
  const timer = useRef(null);
  const start = useRef(null);
  // #endregion

  // #region REQUESTS
  // Requête de création d'un achat
  const requestGetAchat = useJsonRequest({
    command: GET,
    getMode: GET_SINGLE,
    resource: RESSOURCE_ACHATS,
    silent: true,
    url: URL_BASE,
  });
  // #endregion

  // #region EVENTS
  const handleClose = async () => {
    if (window.confirm("Si vous quittez cet écran, vous ne pourrez pas récupérer votre ticket. \n Etes vous sûr? ")) {
      onClose();
    }
  };

  // #region HOOK D'EFFET
  // Réception d'un idAchat
  useEffect(() => {
    if (!idAchat || !visible) {
      return;
    }

    start.current = Date.now();
    requestGetAchat(idAchat);
  }, [requestGetAchat, idAchat, visible]);

  // Vérification des données reçues
  useEffect(() => {
    if (!dataReceived || !visible) {
      return;
    }

    // Récupération du status du paiement
    switch (dataReceived.statusPayzen) {
      case null:
      case undefined:
        // On teste combien de temps s'est écoulé depuis le premier lancement
        let date = Date.now();

        // S'il ça fait plus de 5 minutes, on annule le paiement
        if (date - start.current >= PAYMENT_DELAY) {
          // Clear de la fonction de relance de la requête achat
          clearTimeout(timer.current);

          // Fermeture de la modale
          onClose(dataReceived);
          return;
        }

        // En attente du paiement, on relance la requete dans 5 secondes
        timer.current = setTimeout(() => {
          clearTimeout(timer.current);
          requestGetAchat(idAchat);
        }, 5000);
        break;

      default:
        // On clear la fonction de relance de la requete de l'achat
        clearTimeout(timer.current)

        // Appel de la fonction callback sur la fermeture de la modale et envoi du status au parent
        onClose(dataReceived);
        break;
    }
  }, [dataReceived, visible, requestGetAchat, onClose, idAchat]);
  // #endregion

  // #region INTERFACE
  return (
    <Modale
      height="300px"
      onClose={handleClose}
      title={t("payment.title")}
      visible={visible}
      width="320px"
    >
      <div className="ModaleAttentePaiementTPE-Container">
        <div className="ModaleAttentePaiementTPE-TextContainer">
          <p>{t("payment.showCB")}</p>
        </div>
        <Fab icon="contactless" className="Animated" />
      </div>
    </Modale>
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link ModaleAttentePaiementTPE}
 * @typedef {Object} ModaleAttentePaiementTPE.propTypes
 * @property {number} [idAchat=null] Id de l'achat sur lequel vérifier le statut du paiement
 * @property {function} onClose Fonction callback sur fermeture de la modale
 * @property {boolean} visible True pour afficher la modale, false sinon
*/
ModaleAttentePaiementTPE.propTypes = {
  idAchat: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

// Valeurs par défault des propriétés
ModaleAttentePaiementTPE.defaultProps = {
  idAchat: null
};
// #endregion

export { ModaleAttentePaiementTPE };
