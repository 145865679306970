/**
 * Sélecteurs pour la gestion du composant de chargement
 * @category Loading
 * @hideconstructor
 */
class LoadingStoreSelector {
  /**
   * Sélection de l'object contenant les informations sur l'affichage du chargement
   * @private
   * @param {object} state state de l'application
   * @returns {object} Object contenant les informations sur l'affichage du chargement
   */
  static selectLoading(state) {
    return state.manageLoading.loading;
  }

  /**
   * Sélection de la variable permettant de connaitre l'état du composant de chargement
   * @param {object} state state de l'application
   * @returns {boolean} Variable permettant de connaitre l'état du composant de chargement (affiché/caché)
   */
  static selectShowLoading(state) {
    return LoadingStoreSelector.selectLoading(state).showLoading;
  }
}

// Exports
export const selectShowLoading = LoadingStoreSelector.selectShowLoading;
