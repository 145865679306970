// #region CONSTANTES
// Clé API
export const API_KEY = "AIzaSyCI6iCv6jssgp_KmJToFq31dOIQPw1d3rk";

// Niveau de zoom
export const ZOOM_COUNTRY = 5;
export const ZOOM_CITY = 12;
export const ZOOM_STREET = 16;
export const ZOOM_ADDRESS = 18;

// Icônes
export const ICON_ACTIVE = {
  path:
    "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
  fillColor: "#35A7D7",
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 2
};

export const ICON_INACTIVE = {
  path:
    "M12 6.5c1.38 0 2.5 1.12 2.5 2.5 0 .74-.33 1.39-.83 1.85l3.63 3.63c.98-1.86 1.7-3.8 1.7-5.48 0-3.87-3.13-7-7-7-1.98 0-3.76.83-5.04 2.15l3.19 3.19c.46-.52 1.11-.84 1.85-.84zm4.37 9.6l-4.63-4.63-.11-.11L3.27 3 2 4.27l3.18 3.18C5.07 7.95 5 8.47 5 9c0 5.25 7 13 7 13s1.67-1.85 3.38-4.35L18.73 21 20 19.73l-3.63-3.63z",
  fillColor: "black",
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 2
};

export const ICON_HS = {
  path:
    "M12 6.5c1.38 0 2.5 1.12 2.5 2.5 0 .74-.33 1.39-.83 1.85l3.63 3.63c.98-1.86 1.7-3.8 1.7-5.48 0-3.87-3.13-7-7-7-1.98 0-3.76.83-5.04 2.15l3.19 3.19c.46-.52 1.11-.84 1.85-.84zm4.37 9.6l-4.63-4.63-.11-.11L3.27 3 2 4.27l3.18 3.18C5.07 7.95 5 8.47 5 9c0 5.25 7 13 7 13s1.67-1.85 3.38-4.35L18.73 21 20 19.73l-3.63-3.63z",
  fillColor: "red",
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 2
};
// #endregion
