import * as Constants from "./constants";

/**
 * Actions pour la gestion du composant de chargement
 * @category Loading
 * @hideconstructor
 */
class LoadingStoreAction {
  /**
   * Dissimulation de l'indicateur de chargement
   */
  static hideLoading() {
    return {
      type: Constants.SHOW_LOADING,
      value: false
    };
  }

  /**
   * RAZ de l'indicateur de chargement
   */
  static resetLoading() {
    return {
      type: Constants.RESET_LOADING
    };
  }

  /**
   * Affichage de l'indicateur de chargement
   */
  static showLoading() {
    return {
      type: Constants.SHOW_LOADING,
      value: true
    };
  }
}

export const hideLoading = LoadingStoreAction.hideLoading;
export const resetLoading = LoadingStoreAction.resetLoading;
export const showLoading = LoadingStoreAction.showLoading;
