/**
 * Sélecteurs pour la gestion du store du header
 * @category Header
 * @hideconstructor
 */
class HeaderStoreSelector {
  /**
   * Sélection de l'information de visibilité du bouton back
   * @param {object} state state de l'application
   * @returns {boolean} Boolean pour indiquer la visibilité du bouton back
   */
  static selectBackVisible(state) {
    return HeaderStoreSelector.selectManageHeader(state).backVisible;
  }

  /**
   * Sélection du store pour la gestion du header
   * @param {object} state State de l'application
   * @returns {object} Store pour la gestion du header
   */
  static selectManageHeader(state) {
    return state.manageHeader;
  }
}

// Exports
export const selectBackVisible = HeaderStoreSelector.selectBackVisible;
