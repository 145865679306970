import PropTypes from "prop-types";
import React from "react";
import Modal from "react-awesome-modal";
import { Fab } from "@rmwc/fab";

import "@rmwc/fab/styles";
import "./Modale.css";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher une modale
 * @class
 * @category Modale
 * @param {Modale.propTypes} props
 */
function Modale(props) {
  const {
    children,
    height,
    onClose,
    title,
    visible,
    width
  } = props;

  return (
    <Modal
      effect="fadeInUp"
      height={height}
      onClickAway={onClose}
      visible={visible}
      width={width}
    >
      <div className="Modale-Container">
        <div className="Modale-Title-Container">
          {title}
          <div className="Modale-Button-Container">
            {onClose &&
              <Fab
                icon="close"
                mini
                onClick={onClose}
              />
            }
          </div>
        </div>
        {children}
      </div>
    </Modal >
  );
}
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link Modale}
 * @typedef {Object} Modale.propTypes
 * @property {object} children Composant enfant
 * @property {string} [height="450"] Hauteur de la modale
 * @property {function} [onClose=null] Fonction de callback de fermeture
 * @property {string} title Titre de la modale
 * @property {boolean} visible true pour afficher la fenêtre
 * @property {string} [width="400"] largeur de la modale
 */
Modale.propTypes = {
  children: PropTypes.object.isRequired,
  height: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  width: PropTypes.string,
};

Modale.defaultProps = {
  height: "450",
  onClose: null,
  width: "400",
};
// #endregion

export { Modale };
