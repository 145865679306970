// https://github.com/diegomura/react-pdf/issues/1014#issuecomment-699650993

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { pdf } from "@react-pdf/renderer";
import { CircularProgress } from "@rmwc/circular-progress";

import "@rmwc/circular-progress/styles";
import "./PDFViewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher un PDF
 * @category PDFViewer
 */
function PDFViewer(props) {
  //#region INITIALISATION
  // Récupération des props
  const {
    children
  } = props;

  // Initialisation du state
  const [pdfUrl, setPdfUrl] = useState(null);
  // #endregion

  // #region HOOK D'EFFET
  useEffect(() => {
    if (!children) {
      return;
    }
    
    const child = React.Children.only(children);

    pdf(child).toBlob().then(blob => {
      setPdfUrl(URL.createObjectURL(blob));
    });
  }, [children]);
  // #endregion

  // #region INTERFACE
  // Affichant du composant de chargement
  const renderLoader = () => {
    return (
      <div className="PDFViewer-LoaderContainer">
        <CircularProgress
          size="xlarge"
          style={{ color: "#35A7D7" }}
        />
      </div>
    )
  };

  // Attente du pdf avant de charger
  if (!pdfUrl) {
    return null;
  }

  // Interface
  return (
    <Document
      file={pdfUrl}
      loading={renderLoader()}
    >
      <Page
        pageNumber={1}
        renderMode='svg'
      />
    </Document>
  )
  //#endregion
};
//#endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link PDFViewer}
 * @typedef {Object} PDFViewer.propTypes
 * @property {object} children JSX du document PDF à afficher
*/
PDFViewer.propTypes = {
  children: PropTypes.object
};

// Valeurs par défault des propriétés
PDFViewer.defaultProps = {
  children: null
};
// #endregion

export { PDFViewer };
