import * as Constants from "./constants";

/**
 * Reducer de gestion des données renvoyées par le serveur
 * @private
 * @param {object} state Le state de l'application
 * @param {object} action L'action contenant le type et la valeur
 */
function manageDataResource(state = Constants.INITIAL_STATE, action) {
  let nextState;
  switch (action.type) {
    case Constants.ERROR_DATA_RESOURCE:
      // Erreur détectée pour une ressource
      nextState = { ...state };
      nextState.error[action.value.name] = action.value.error;
      return nextState || state;

    case Constants.SET_DATA_RESOURCE:
      // Mise à jour des données pour une ressource
      nextState = { ...state };
      nextState.dataReceived[action.value.name] = action.value.data;
      return nextState || state;

    case Constants.RAZ_ALL_DATA_RESOURCE:
      // RAZ de toutes les données des ressources
      nextState = {
        ...state,
        dataReceived: {},
        dataUpdated: {},
        state: {},
        error: {}
      };
      return nextState || state;

    case Constants.RAZ_DATA_RESOURCE:
      // RAZ des données pour une ressource
      nextState = { ...state };
      nextState.dataReceived[action.value.name] = null;
      return nextState || state;

    case Constants.STATE_DATA_RESOURCE:
      // Mise à jour de l'état des données pour une ressource
      nextState = { ...state };
      nextState.state[action.value.name] = action.value.state;
      nextState.dataUpdated[action.value.name] = action.value.data;
      return nextState || state;

    default:
      return state;
  }
}

export { manageDataResource };
