// Actions
export const SET_DATA_RESOURCE = "SET_DATA_RESOURCE";
export const RAZ_DATA_RESOURCE = "RAZ_DATA_RESOURCE";
export const STATE_DATA_RESOURCE = "STATE_DATA_RESOURCE";
export const ERROR_DATA_RESOURCE = "ERROR_DATA_RESOURCE";
export const RAZ_ALL_DATA_RESOURCE = "RAZ_ALL_DATA_RESOURCE";

// Statut des données mises à jour
export const DATA_NO_STATE = -1;
export const DATA_CREATED = 0;
export const DATA_UPDATED = 1;
export const DATA_DELETED = 2;

// Statut initial
export const INITIAL_STATE = {
  dataReceived: {}, // Les données reçues sont stockées dans un object ayant comme nom le nom de la resource (lors d'un GET)
  dataUpdated: {}, // Les données mises à jour sont stockées dans un object ayant comme nom le nom de la resource (lors d'un POST, PUT)
  state: {}, // L'état des données mise à jour (0=CREATED, 1=UPDATED, 2=DELETED), stocké dans un object ayant comme nom le nom de la resource
  error: {} // Les erreurs, stockées dans un object ayant comme nom le nom de la resource
};
