import * as Constants from "./constants";

/**
 * Actions pour la gestion du token
 * @category Store
 * @hideconstructor
 */
class TokenStoreAction {
  /**
   * Mise à jour du token
   * @param {string} token Token
   */
  static setToken(token) {
    return {
      type: Constants.SET_TOKEN,
      value: token
    };
  }
}

// Exports
export const setToken = TokenStoreAction.setToken;
