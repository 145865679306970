import { hideLoading, showLoading } from "App/Loading/Store/actions";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'envoyer les informations reçues par Payzen
 * @category Paiement
 */
function Paiement() {
  // #region INITIALISATION
  // Initialisation du dispatch
  const dispatch = useDispatch();
  // #endregion

  // #region HOOK D'EFFET
  // Récupération des information de Payzen
  useEffect(() => {
    setTimeout(() => {
      // Affichage du loader
      dispatch(showLoading());

      // Envoi des informations au parent
      window.top.postMessage({ text: 'payzen infos', payzenInfos: window.location.search.substring(1) });

      return (
        // Suppression du loader à la fermeture
        dispatch(hideLoading())
      )
    }, 100);
  }, [dispatch]);
  // #endregion

  // #region
  return (
    <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
    </div>
  );
  // #endregion
};
// #endregion

export { Paiement };
