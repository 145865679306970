/**
 * Sélecteurs pour la gestion de l'indicateur de géolocalisation bloquée
 * @category Store
 * @hideconstructor
 */
class GeolocationStoreSelector {
  /**
   * Sélection du boolean de l'indicateur de géolocalisation bloquée
   * @param {object} state state de l'application
   * @returns {GeolocationBoolean} L'indicateur de géolocalisation bloquée
   */
  static selectIsGeolocationBlocked(state) {
    return state.manageGeolocationBlocked.isGeolocationBlocked;
  }
}

export const selectIsGeolocationBlocked = GeolocationStoreSelector.selectIsGeolocationBlocked;
