import { resetLoading } from "App/Loading/Store/actions";
import { resetGeolocationBlocked } from "App/Store/Geolocation/actions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

// #region FONCTION COMPOSANT
/**
 * Composant de réinitialisation du store
 * @class
 * @category App
 */
function ResetStore() {
  // #region INITIALISATION
  // Récupération du dispatch
  const dispatch = useDispatch();
  // #endregion

  // #region HOOK D'EFFET
  // Réinitialisation du store des modifications en cours au chargement de l'appli
  useEffect(() => {
    // RAZ du loading
    dispatch(resetLoading());
    // RAZ de l'indicateur de géolocalisation bloquée
    dispatch(resetGeolocationBlocked());
  }, [dispatch]);
  // #endregion

  // #region INTERFACE
  return null;
  // #endregion
}
// #endregion

export { ResetStore };
