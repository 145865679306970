import { AchatListItem } from "./AchatListItem";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CollapsibleList, SimpleListItem } from "@rmwc/list";
import car from "Resources/aire-de-vidange-camping-car.svg";
import parking from "Resources/parking-sign.svg";

import '@rmwc/list/styles';
import "./AchatList.css";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher la liste pour achat sur une borne
 * @category Achat
 * @param {AchatList.propTypes} props Les propriétés du composant
 */
function AchatList(props) {
  //#region INITIALISATION
  // Récupération des props
  const {
    categorie,
    doShowSelectedOnly,
    doShowValidCommand,
    onChange,
    onSelectDetailAchat
  } = props;

  // Initialisation du state
  const [isOneItemSelected, setIsOneItemSelected] = useState(false);
  // #endregion

  // #region EVENTS
  const selectIcon = (service) => {
    switch (service) {
      case "1":
        return car
      case "2":
      case "3":
      case "7":
        return "bolt"
      case "4":
        return parking
      default:
        return "info"
    }
  }
  // #endregion

  //#region INTERFACE
  return (
    doShowSelectedOnly && !isOneItemSelected ?
      null
      :
      <CollapsibleList
        key={categorie.id}
        defaultOpen
        handle={
          <SimpleListItem
            className="Achat-HeaderList"
            graphic={selectIcon(categorie.id)}
            metaIcon="chevron_right"
            text={categorie.name}
          />
        }
      >
        {
          categorie.services.map((item) => {
            return (
              <AchatListItem
                doShowSelectedOnly={doShowSelectedOnly}
                doShowValidCommand={doShowValidCommand}
                key={item.idService}
                lock={isOneItemSelected}
                onChange={onChange}
                onSelectItem={(value) => setIsOneItemSelected(value)}
                onSelectDetailAchat={onSelectDetailAchat}
                service={item}
              />
            )
          })
        }
      </ CollapsibleList>
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
* Type des propriétés de {@link AchatList}, hérite des propriétés de {@link AchatListItem}
* @typedef {Object} AchatList.propTypes
* @property {object} categorie La liste des catégories des items de la borne
*/
AchatList.propTypes = {
  categorie: PropTypes.object.isRequired
};
// #endregion

export { AchatList };
