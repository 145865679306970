/**
 * Sélecteurs pour la gestion des référentiels
 * @category Referentiel
 * @hideconstructor
 */
class ReferentielStoreSelector {
  /**
   * Sélection du store pour les référentiel
   * @param {object} state State de l'application
   * @returns {array} Le store des référentiels
   */
  static selectReferentiel(state) {
    return state.manageReferentiel;
  }

  /**
   * Sélection des données des référentiel serviceType
   * @param {object} state State de l'application
   * @returns {array} Les données serviceTypes
   */
  static selectDataReferentielServiceTypes(state) {
    return ReferentielStoreSelector.selectReferentiel(state).dataTypes;
  }

  /**
   * Sélection des données des référentiel UniteTypes
   * @param {object} state State de l'application
   * @returns {array} Les données UniteTypes
   */
  static selectDataReferentielUniteTypes(state) {
    return ReferentielStoreSelector.selectReferentiel(state).dataUniteTypes;
  }

}

// Exports
export const selectDataReferentielServiceTypes = ReferentielStoreSelector.selectDataReferentielServiceTypes;
export const selectDataReferentielUniteTypes = ReferentielStoreSelector.selectDataReferentielUniteTypes;

