import { URL, RESSOURCE_AUTHENTIFICATION } from "../API/AuthentificationAPI";
import { setToken } from "../Store/actions";
import { selectToken } from "../Store/selectors";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { POST } from "Request/Components/RequestUtils";
import { useJsonRequest } from "Request/Components/useJsonRequest";
import { selectDataResourceReceived } from "Request/Store/selectors";

// #region CONSTANTES
const DELAY_REQUEST = 50; // 50 minutes
const DELAY_VERIFICATION = 300000; // 5 minutes
// #endregion

//#region FONCTION COMPOSANT
/**
 * Composant permettant de vérifier l'état du token
 * @category Authentification
 */
function Authentification() {
  // #region INITIALISATION
  // Initialisation du state
  const [checkToken, setCheckToken] = useState(true);

  // Initialisation des selecteurs
  const dataReceived = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_AUTHENTIFICATION)); // Données issues de la requête
  const token = useSelector((state) => selectToken(state));

  // Initialisation des références
  const timerId = useRef(-2);

  // Initialisation du dispatch
  const dispatch = useDispatch();
  // #endregion

  // #region UTILS
  // Arrêt du timer
  const stopTimer = () => {
    if (timerId.current >= 0) {
      clearTimeout(timerId.current);
    };
  };
  // #endregion

  // #region REQUEST
  // Requête de récupération d'unt= token
  const requestGetAuthentification = useJsonRequest({
    command: POST,
    resource: RESSOURCE_AUTHENTIFICATION,
    silent: true,
    tokenObligatory: false,
    url: URL
  });
  // #endregion

  // Récupération du token
  useEffect(() => {
    if (!dataReceived) {
      return;
    }

    dispatch(setToken(dataReceived.token));
  }, [dispatch, dataReceived]);

  // Détection de nouvelles données reçues
  useEffect(() => {
    if (!checkToken) {
      stopTimer();
      return;
    }

    let params = {
      username: "pwa.altekip",
      password: "pw@21@ltekip"
    };

    timerId.current = -1;
    let tokenDate = token.date ? moment(token.date) : null;

    // Si pas de token ou que la différence entre la date de récupération et la date du moment > 50 minutes, on lance a requête tout de suite
    if (!token.token || (token.date && moment().diff(tokenDate, "minutes") > DELAY_REQUEST)) {
      requestGetAuthentification(params);
    }

    // On relance la vérification dans 5 minutes
    stopTimer();
    timerId.current = setTimeout(() => {
      setCheckToken(false);
      setCheckToken(true);
    }, DELAY_VERIFICATION);

  }, [requestGetAuthentification, checkToken]);
  // #endregion

  // #region INTERFACE
  // Pas d'interface, le composant sert à récuperer un token
  return null;
  // #endregion
};
// #endregion

export { Authentification };
