import * as Constants from "./constants";

/**
 * Reducer de gestion du boolean isGeolocationBlocked dans le store
 * @private
 * @param {object} state Le state de l'application
 * @param {object} action L'action contenant le type et la valeur
 */
function manageGeolocationBlocked(state = Constants.INITIAL_STATE, action) {
  let nextState;
  switch (action.type) {
    case Constants.RESET_GEOLOCATION_BLOCKED:
      // Mise à jour de l'indicateur de géolocalisation bloquée
      nextState = {
        ...state,
        isGeolocationBlocked: false
      };
      return nextState || state;

    case Constants.SET_GEOLOCATION_BLOCKED:
      // Mise à jour du state
      nextState = {
        ...state,
        isGeolocationBlocked: action.value
      };
      return nextState || state;

    default:
      return state;
  }
}

export { manageGeolocationBlocked };
