import * as Constants from "./constants";

/**
 * Reducer de gestion du header dans le store
 * @private
 * @param {object} state Le state de l'application
 * @param {object} action L'action contenant le type et la valeur
 */
function manageHeader(state = Constants.INITIAL_STATE, action) {
  let nextState;
  switch (action.type) {
    case Constants.SET_BACK_VISIBLE:
      // Gestion de la visibilité du bouton back dans le haeder
      nextState = {
        ...state,
        backVisible: action.value
      };
      return nextState || state;

    default:
      return state;
  }
}

export { manageHeader };
