import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'fr',
    resources: {
      en: {
        translation: {
          accueil: {
            searchBorne: "Find a terminal",
            distribution: "My distribution",
            label: "Language",
            language: "English",
            languageIcon: "https://cdn-icons-png.flaticon.com/512/299/299688.png",
          },
          achat: {
            borne: "Terminal",
            cancel: "Cancel",
            confirmTitle: "Confirmation of license plate",
            confirmMessage: "Are you sure to validate this plate :",
            confirmMessageMail: "Are you sure to validate this mail :",
            confirmTitleMail: "Confirmation of mail",
            confirmMessageTaxe: "Are you sure to validate this tax :",
            confirmTitleTaxe: "Confirmation of city tax",
            email: "Mail adress",
            error: "An error occured during payment,",
            error2: "please try again",
            hideMessage: "OK",
            immatriculation: "License plate",
            needImmatriculation: "Please fill in your license plate number",
            places: "places are available",
            serviceOut: "Service not available",
            serviceTaxe: "Applicable tax",
            validationTaxe: "Tax included",
            taxeSejour: "City tax",
            personne: "Person(s)",
            validation: "Validate",
            MontantTaxes: "Amount of tax" //Provisoire
          },
          distribution: {
            accesCode: "Acces code : ",
            depassement: "Time out",
            placeholder: "No distribution in progress...",
            unite: "Unit(s)",
            rachat: "BuyBack"
          },
          language: {
            en: "English",
            languageIconEN: "https://cdn-icons-png.flaticon.com/512/299/299688.png",
            fr: "French",
            languageIconFR: "https://cdn-icons-png.flaticon.com/512/299/299753.png",
            esp: "Spanish",
            languageIconESP: "https://cdn-icons-png.flaticon.com/512/299/299820.png"
          },
          loading: {
            text: "Wait a moment please"
          },
          payment: {
            mode: "Payment mode",
            onDevice: "Payment on the terminal",
            online: "Payment online",
            showCB: "Please present your contactless CB card on the reader.",
            title: "Payment"
          },
          ticket: {
            autorisationNumber: "Authorization number :",
            cardNumber: "Card number :",
            code: "Acces code : ",
            dateBuy: "Purchase date :",
            immatriculation: "Immatriculation :",
            mail: "Mail :",
            mailInfo: "Your ticket has been sent to your inbox or spam folder.",
            order: "Order :",
            price: "Amount :",
            save: "Save",
            shop: "Shop :",
            title: "Thank you for your purchase",
            type: "Type :"
          }
        }
      },
      esp: {
        translation: {
          accueil: {
            searchBorne: "Buscar un terminal",
            distribution: "Mi distribución",
            label: "Lengua",
            language: "Español",
            languageIcon: "https://cdn-icons-png.flaticon.com/512/299/299820.png"
          },
          achat: {
            borne: "Terminal",
            cancel: "Anular",
            confirmMessage: "Seguro que quiere validar esta matrícula :",
            confirmTitle: "Confirmación de la matrícula",
            confirmMessageMail: "Confirme esta dirección de correo electrónico ?",
            confirmTitleMail: "Confirmación de correo electrónico",
            confirmMessageTaxe: "Seguro que quiere validar esta impuesto ?",
            confirmTitleTaxe: "Confirmación de la impuesto municipal",
            email: "Mail Adress",
            error: "An error occured during payment,",
            error2: "please try again",
            hideMessage: "OK",
            immatriculation: "Matrícula",
            needImmatriculation: "Indique la matrícula de su vehículo",
            places: "Plazas disponibles",
            serviceOut: "Servicio indisponible",
            serviceTaxe: "Impuesto aplicable",
            validationTaxe: "Validar con impuestos ",
            taxeSejour: "Impuesto municipal",
            personne: "Persona(s)",
            validation: "Validar",
            MontantTaxes: "Impuesto" //Provisoire
          },
          distribution: {
            accesCode: "Codigo de acceso : ",
            depassement: "Tiempo de espera",
            placeholder: "Sin distribución en curso...",
            unite: "Unidad(es)",
            rachat: "Recompra"
          },
          language: {
            en: "Inglés",
            languageIconEN: "https://cdn-icons-png.flaticon.com/512/299/299688.png",
            fr: "Francés",
            languageIconFR: "https://cdn-icons-png.flaticon.com/512/299/299753.png",
            esp: "Espanol",
            languageIconESP: "https://cdn-icons-png.flaticon.com/512/299/299820.png"
          },
          loading: {
            text: "Un momento por favor"
          },
          payment: {
            mode: "Modo de pago",
            onDevice: "Pago por tarjeta en el terminal",
            online: "Pago Online",
            showCB: "Presente su tarjeta en el lector sin contacto",
            title: "Pago"
          },
          ticket: {
            autorisationNumber: "Número de autorización :",
            cardNumber: "Número de tarjeta :",
            code: "Código de acceso :",
            dateBuy: "Fecha de compra :",
            immatriculation: "Matrícula :",
            mail: "Mail :",
            mailInfo: "Su ticket ha sido enviado a su bandeja de entrada o carpeta de correo no deseado",
            order: "Pedido :",
            price: "Importe :",
            save: "Guardar ",
            shop: "Tienda :",
            title: "Gracias por su compra ",
            type: "Tipo :"
          }
        }
      },
      fr: {
        translation: {
          accueil: {
            searchBorne: "Trouver une borne",
            distribution: "Ma distribution",
            label: "Langue",
            language: "Français",
            languageIcon: "https://cdn-icons-png.flaticon.com/512/299/299753.png"
          },
          achat: {
            borne: "Borne",
            cancel: "Annuler",
            confirmMessage: "Etes vous sûr de valider la plaque :",
            confirmTitle: "Confirmation de la plaque",
            confirmMessageMail: "Confirmez vous cette adresse mail ?",
            confirmTitleMail: "Confirmation de l'adresse mail",
            confirmMessageTaxe: "Etes vous sûr de valider la taxe ?",
            confirmTitleTaxe: "Confirmation de la taxe de sejour",
            email: "Adresse mail",
            error: "Une erreur a eu lieu durant l'achat,",
            error2: "veuillez réessayer",
            hideMessage: "Compris",
            immatriculation: "plaque d'immatriculation",
            needImmatriculation: "Veuillez renseigner votre plaque d'immatriculation",
            places: "places disponibles",
            serviceOut: "Service indisponible",
            serviceTaxe: "Taxe applicable",
            validationTaxe: "Valider avec taxes",
            taxeSejour: "Taxe de sejour",
            personne: "Personne(s)",
            validation: "Valider",
            MontantTaxes: "Montant des taxes"

          },
          distribution: {
            accesCode: "Code d'accès : ",
            depassement: "Temps dépassé",
            placeholder: "Pas de distribution en cours...",
            unite: "Unité(s)",
            rachat: "Rachat"
          },
          language: {
            en: "Anglais",
            languageIconEN: "https://cdn-icons-png.flaticon.com/512/299/299688.png",
            fr: "Français",
            languageIconFR: "https://cdn-icons-png.flaticon.com/512/299/299753.png",
            esp: "Espagnol",
            languageIconESP: "https://cdn-icons-png.flaticon.com/512/299/299820.png"
          },
          loading: {
            text: "un instant s'il vous plait"
          },
          payment: {
            mode: "Mode de paiement",
            onDevice: "Paiement CB sur la borne",
            online: "Paiement en ligne",
            showCB: "Merci de présenter votre carte CB sans contact sur le lecteur.",
            title: "Paiement"
          },
          ticket: {
            autorisationNumber: "Numéro d'autorisation :",
            cardNumber: "Numero de carte :",
            code: "Code d'accès :",
            dateBuy: "Date d'achat :",
            immatriculation: "Immatriculation :",
            mail: "Email :",
            mailInfo: "Votre ticket a été envoyé sur votre boîte de réception ou votre dossier de courrier indésirable.",
            order: "Commande :",
            price: "Montant :",
            save: "Sauvegarder",
            shop: "Boutique :",
            title: "Merci pour votre achat",
            type: "Type :"
          }
        }
      }
    }
  });

export default i18n;
