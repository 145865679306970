/**
 * Sélecteurs pour la gestion des données renvoyées par le serveur
 * @category Data
 * @hideconstructor
 */
class DataResourceStoreSelector {
  /**
   * Sélection des données renvoyée par le serveur pour une ressource lors d'un GET
   * @param {object} state state de l'application
   * @param {string} name nom de la ressource
   * @returns {array/object} Les données renvoyées par le serveur pour la resource _name_
   */
  static selectDataResourceReceived(state, name) {
    return state.manageDataResource.dataReceived[name];
  }

  /**
   * Sélection des données renvoyée par le serveur pour une ressource lors d'uenmise à jour (POST, PUT)
   * @param {object} state state de l'application
   * @param {string} name nom de la ressource
   * @returns {array/object} Les données renvoyées par le serveur pour la resource _name_
   */
  static selectDataResourceUpdated(state, name) {
    return state.manageDataResource.dataUpdated[name];
  }

  /**
   * Sélection des données renvoyée par le serveur pour une ressource
   * @param {object} state state de l'application
   * @param {string} name nom de la ressource
   * @returns {string} L'erreur renvoyées par le serveur pour la resource _name_
   */
  static selectErrorDataResource(state, name) {
    return state.manageDataResource.error[name];
  }

  /**
   * Sélection de l'état des données mise à jour par le serveur pour une ressource
   * @param {object} state state de l'application
   * @param {string} name nom de la ressource
   * @returns {number} Etat des données (0=CREATED, 1=UPDATED, 2=DELETED) pour la resource _name_
   */
  static selectStateDataResource(state, name) {
    return state.manageDataResource.state[name];
  }
}

export const selectDataResourceReceived = DataResourceStoreSelector.selectDataResourceReceived;
export const selectDataResourceUpdated = DataResourceStoreSelector.selectDataResourceUpdated;
export const selectErrorDataResource = DataResourceStoreSelector.selectErrorDataResource;
export const selectStateDataResource = DataResourceStoreSelector.selectStateDataResource;
