import * as Constants from "./constants";

/**
 * Actions pour la gestion des référentiels
 * @category Referentiel
 * @hideconstructor
 */
class ReferentielStoreAction {
  /**
   * RAZ de la liste des référentiels en local
   */
  static resetDataReferentiel() {
    return {
      type: Constants.RESET_DATA_REFERENTIEL
    };
  }

  /**
   * Mise à jour du référenciel des types de service
   * @param {array} data Liste des types de service
   */
  static setDataServicesTypes(data) {
    return {
      type: Constants.SET_DATA_SERVICE_TYPES,
      value: data
    };
  }

  /**
   * Mise à jour du référenciel des types d'unité
   * @param {array} data Liste des types d'unité
   */
  static setDataUniteTypes(data) {
    return {
      type: Constants.SET_DATA_UNITE_TYPES,
      value: data
    };
  }
}

// Exports
export const resetDataReferentiel = ReferentielStoreAction.resetDataReferentiel;
export const setDataServicesTypes = ReferentielStoreAction.setDataServicesTypes;
export const setDataUniteTypes = ReferentielStoreAction.setDataUniteTypes;
