// Actions
export const RESET_LOADING = "RESET_LOADING";
export const SHOW_LOADING = "SHOW_LOADING";

// Statut initial
export const INITIAL_STATE = {
  loading: {
    showLoading: 0,
  }
};
