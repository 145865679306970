import { PAYZEN, PAYZEN_TPE } from "../Constants";
import { Modale } from "App/Modale/Components/Modale";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Fab } from "@rmwc/fab";

import "@rmwc/fab/styles";
import "./ModaleTypePaiement.css";

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher le choix du mode de paiement
 * @category Achat
 */
function ModaleTypePaiement(props) {
  //#region INITIALISATION
  // Récupération des props
  const {
    onClick,
    onClose,
    visible
  } = props;

  // Traduction i18n
  const { t } = useTranslation();
  // #endregion

  // #region INTERFACE
  return (
    <Modale
      height="300"
      onClose={onClose}
      title={t("payment.mode")}
      visible={visible}
      width="300"
    >
      <div className="ModaleTypePaiement-Container">
        <Fab
          icon="payment"
          label={t("payment.online")}
          onClick={() => { onClick(PAYZEN) }}
        />
        <Fab
          icon="contactless"
          label={t("payment.onDevice")}
          onClick={() => { onClick(PAYZEN_TPE) }}
        />
      </div>
    </Modale>
  );
  // #endregion
};
// #endregion

// #region PROPRIETES
/**
 * Type des propriétés de {@link ModaleTypePaiement}
 * @typedef {Object} ModaleTypePaiement.propTypes
 * @property {function} onClick Fonction callback sur sélection d'un mode de paiement
 * @property {function} onClose Fonction callback sur fermeture de la modale
 * @property {boolean} visible True pour afficher la modale, false sinon
*/
ModaleTypePaiement.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};
// #endregion

export { ModaleTypePaiement };
