/**
 * Sélecteurs pour la gestion de l'achat
 * @category Store
 * @hideconstructor
 */
class AchatStoreSelector {
  /**
   * Sélection de l'objet contenant les informations de l'achat
   * @param {object} state state de l'application
   * @returns {AchatObject} Informations de l'achat
   */
  static selectAchat(state) {
    return state.manageAchat.achats;
  }
}

export const selectAchat = AchatStoreSelector.selectAchat;
