// Chemins dans le site
export const MAP = "/Accueil";
export const BORNE = "/Borne";
export const DISTRIBUTION_EN_COURS = "/MaDistribution";

// URL
export const URL_BASE = "https://apiionycar.netbase.fr/api";
// export const URL_BASE = "http://localhost:8000/api";

// Couleur des paragraphes
export const PRIMARY_COLOR = "#35A7D7";
export const SECONDARY_COLOR = "black";
