import { BottomNavBar } from "./BottomNavBar";
import { LanguageSelector } from "./LanguageSelector";
import { selectToken } from "App/Authentification/Store/selectors";
import { URL_BASE } from "App/Constants";
import { setBackVisible } from "App/Header/Store/actions";
import { setGeolocationBlocked } from "App/Store/Geolocation/actions";
import { selectIsGeolocationBlocked } from "App/Store/Geolocation/selectors";
import { showError } from "App/Toast/Toast";
import { Distribution } from "Distribution/Components/Distribution";
import { RESSOURCE_TERMINALS } from "Map/API/MapApi";
import {
  ICON_ACTIVE,
  ICON_HS,
  ICON_INACTIVE,
  ZOOM_ADDRESS,
  ZOOM_COUNTRY
} from "Map/Constants";
import { Map } from "Map/Components/Map";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { GET } from "Request/Components/RequestUtils";
import { GET_LIST, useJsonRequest } from "Request/Components/useJsonRequest";
import { selectDataResourceReceived } from "Request/Store/selectors";

import "@rmwc/drawer/styles";
import "@rmwc/fab/styles";
import '@rmwc/list/styles';
import "./Accueil.css";


// #region CONSTANTES
// Indice des TabBar
const TAB_MAP = 0;
const TAB_DISTRIBUTION = 1;
// Erreur de géolocalisation
const GEOLOCATION_BLOCKED = 1;
// #endregion

// #region FONCTION COMPOSANT
/**
 * Composant permettant d'afficher la page d'accueil
 * @category Accueil
 */
function Accueil() {
  //#region INITIALISATION
  // Récupération des props
  const location = useLocation();
  const selectedTab = location.state ? location.state.selectedIndexTab : null;

  // Initialisation du state
  const [mapInfo, setMapInfo] = useState({ coordinates: null, zoom: ZOOM_COUNTRY, showMarker: false });
  const [selectedIndexTab, setSelectedIndexTab] = useState(selectedTab ? selectedTab : TAB_MAP);

  // Initialisation des selecteurs
  const dataReceived = useSelector((state) => selectDataResourceReceived(state, RESSOURCE_TERMINALS)); // Données issues de la requête
  const token = useSelector((state) => selectToken(state));
  const isGeolocationBlocked = useSelector((state) => selectIsGeolocationBlocked(state));

  // Initialisation des références
  const terminaux = useRef([]);
  const timer = useRef(-1);

  // Initialisation de l'indicateur de géolocalisation bloquée
  // Le sélecteur isGeolocationBlocked n'est pas a jour dans le setTimeOut, on est obligé de le forcer
  //cf. https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks
  const geolocationRef = useRef(isGeolocationBlocked);
  geolocationRef.current = isGeolocationBlocked;

  // Récupération de l'historique
  const history = useHistory();

  // Récupération du dispatch
  const dispatch = useDispatch();
  //#endregion

  // #region UTILS
  const getLocation = () => {
    if ("geolocation" in navigator) {
      // Suppression des tentatives
      clearTimeout(timer.current);
      // Récupération de la position courante
      navigator.geolocation.getCurrentPosition(
        // Succès
        (position) => { updateMapInfo(position) },
        // Erreur
        (err) => { handleErrorLocation(err) }
      );
    } else {
      // Géolocalisation inaccessible
      showError("Le navigateur ne peut pas vous géolocaliser");
      // Nouvelle tentative
      timer.current = setTimeout(() => {
        getLocation();
      }, 1000);
    }
  }

  // Récupération de l'icône du terminal
  const getTerminalIcon = (etatTerminal) => {
    switch (etatTerminal) {
      case 0:
        return ICON_INACTIVE

      case 1:
        return ICON_ACTIVE

      case 2:
        return ICON_HS

      default:
        break;
    }
  };

  // Mise à jour des infos de la map
  const updateMapInfo = (position) => {
    let newMapInfo = {};

    // Mise à jour des coordonnées
    newMapInfo.coordinates = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };

    // Mise à jour du zoom
    newMapInfo.zoom = ZOOM_ADDRESS;

    // Affichage du marker
    newMapInfo.showMarker = true;

    // Mise à jour du state
    setMapInfo(newMapInfo);
  };
  // #endregion

  // #region REQUEST
  // Requête de récupération des terminaux
  const requetGetTerminals = useJsonRequest({
    command: GET,
    getMode: GET_LIST,
    resource: RESSOURCE_TERMINALS,
    url: URL_BASE
  });
  // #endregion

  // #region EVENTS
  // Sélection d'une borne
  const handleClickBorne = (borne) => {
    if (!borne.id) {
      return;
    }

    if (!borne.active) {
      showError("Cette borne n'est pas disponible");
      return;
    }

    history.push({
      pathname: "/achat",
      state: {
        borne: borne,
        codeAcces: "",
        dateValidite: null,
        depassement: 0,
        immatriculation: "",
        rachat: false,
        servicesActifs: []
      }
    });
  };

  // Gestion de l'erreur de géolocalisation
  const handleErrorLocation = (error) => {
    if (!geolocationRef.current) {
      // Enregistrement dans le store
      dispatch(setGeolocationBlocked(true));
      if (error.code === GEOLOCATION_BLOCKED) {
        showError("Vous n'avez pas autorisé l'application à accéder à votre géolocalisation");
      } else {
        showError(error.message, "Impossible d'accéder à votre géolocalisation");
      }
    }

    timer.current = setTimeout(() => {
      getLocation();
    }, 1000);
  };
  // #endregion

  // #region HOOK D'EFFET
  // On cache le bouton back du header
  useEffect(() => {
    dispatch(setBackVisible(false));
  }, [dispatch]);

  // Récupération de la géolocalisation
  useEffect(() => {
    if (selectedIndexTab !== TAB_MAP) {
      return;
    }

    timer.current = setTimeout(() => {
      getLocation();
    }, 1000);
    return () => clearTimeout(timer.current);
  }, [selectedIndexTab]);

  // Lancement de la requête
  useEffect(() => {
    if (!token) {
      return;
    }

    requetGetTerminals();
  }, [token, requetGetTerminals]);

  // Récupération des terminaux
  useEffect(() => {
    if (!dataReceived) {
      return;
    }

    let d = [...dataReceived];
    let markerList = [];

    d.forEach(terminal => {
      // Formatage de la localisation
      let location = terminal.gpsTerminal.split(',');

      // Formatage de la resource terminal
      let newTerminal = {
        active: terminal.etatTerminal === 1 ? true : false,
        icon: getTerminalIcon(terminal.etatTerminal),
        id: terminal.idTerminal,
        idModePaiement: terminal.idModePaiement,
        location: {
          lat: parseFloat(location[0]),
          lng: parseFloat(location[1])
        },
        name: terminal.nomTerminal,
        zones: terminal.zones
      }

      markerList.push(newTerminal);
    });

    // Enregistrement des terminaux formatés
    terminaux.current = markerList;
  }, [dataReceived]);
  // #endregion

  // #region INTERFACE
  return (
    <div className="Accueil-Container">
      {(selectedIndexTab === TAB_MAP) &&
        <>
          <Map
            center={mapInfo.coordinates}
            isMarkerShown={mapInfo.showMarker}
            markers={terminaux.current}
            onClick={(borne) => { handleClickBorne(borne) }}
            zoom={mapInfo.zoom}
          />
          <LanguageSelector />
        </>
      }
      {
        (selectedIndexTab === TAB_DISTRIBUTION) &&
        <Distribution borne={terminaux.current} />
      }
      <BottomNavBar
        activeTabIndex={selectedIndexTab}
        onChangeActiveTab={(index) => setSelectedIndexTab(index)}
      />
    </div >
  );
  // #endregion
};
// #endregion

export { Accueil };
