import PropTypes from "prop-types";
import React, { useState } from "react";
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from "@rmwc/drawer";
import { List, ListItem, ListItemGraphic } from "@rmwc/list";
import { useTranslation } from "react-i18next";
import { Fab } from "@rmwc/fab";


//#region CONSTANTES

// #endregion

// #region FONCTION COMPOSANT
/**
* Composant permettant de
* @class
* @category LanguageSelector
*/
function LanguageSelector(props) {
  // #region INITIALISATION
  // Initialisation des states
  const [open, setOpen] = useState(false);

  // Initialisation des langages
  const [t, i18n] = useTranslation();
  // #endregion

  // #region UTILS
  const language = [
    {
      label: t("language.en"),
      icon: t("language.languageIconEN"),
      value: 'en'
    },
    {
      label: t("language.fr"),
      icon: t("language.languageIconFR"),
      value: 'fr',
    },
    {
      label: t("language.esp"),
      icon: t("language.languageIconESP"),
      value: 'esp',
    },
  ];
  // #endregion

  // #region REQUEST

  // #endregion

  // #region EVENTS
  // Changement de la langue
  const handleToggleLanguage = (item) => {
    i18n.changeLanguage(item.value)
  };
  // #endregion

  // #region HOOK D'EFFET

  // #endregion

  // #region INTERFACE
  // Affichage de la liste de langue
  const languageList = () => (
    language.map((item, index) => (
      <div key={index} style={{ display: "flex", flexDirection: "row" }}>
        <ListItemGraphic icon={{
          icon: item.icon,
          strategy: 'url',
        }} style={{ alignSelf: "center", marginRight: 0, marginLeft: 15 }} />
        <ListItem onClick={() => handleToggleLanguage(item)}>
          {item.label}
        </ListItem>
      </div>
    ))
  );

  return (
    <>
      <div style={{ position: "absolute", left: 10, bottom: 120 }}>
        <Fab
          icon={{
            icon: t("accueil.languageIcon"),
            strategy: 'url',
          }}
          onClick={() => setOpen(!open)}
          style={{ backgroundColor: "#ffffffc4", border: "2px solid #35A7D7" }}
        />
      </div>
      <Drawer modal open={open} onClose={() => setOpen(false)} >
        <DrawerHeader>
          <DrawerTitle>{t("accueil.label")}</DrawerTitle>
        </DrawerHeader>
        <DrawerContent>
          <List>
            {languageList()}
          </List>
        </DrawerContent>
      </Drawer>
    </>
  );
  // #endregion
};
// #endregion

/**
 * Type des propriétés de {@link LanguageSelector}
 * @typedef {Object} LanguageSelector.propTypes
 * @property {boolean} open State de l'ouverture de la modale
 * @property {function} setOpen Fonction mettant à jour le state de la modale
*/

LanguageSelector.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

export { LanguageSelector };
