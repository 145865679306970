// #region CONSTANTES
export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const PATCH = "PATCH";
export const DELETE = "DELETE";
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const NOT_SUPPORTED = 405;
export const DATA_ERROR = 422;
export const SERVER_ERROR = 500;
export const TIMEOUT = 504;
// #region

/**
 * Utilitaire pour la gestion des appels au serveur : Web service de type REST et données au format JSON
 * @category Data
 * @hideconstructor
 */
class RequestUtils {
  // #region METHODES PUBLIQUES
  /**
   * Web service de type REST pour créer une ressource
   * @category RequestUtils
   * @param {string} urlBase URL de base
   * @param {string} resource Nom de la ressource
   * @param {object} data Données à envoyer au format JSON
   * @param {number} [timeout=120000] Timeout en ms
   * @param {string} token Token d'identification
  */
  static createResource(urlBase, token, resource, data, timeout = 120000) {
    return RequestUtils._sendRequest(
      token,
      RequestUtils._constructUrl(urlBase, resource, null, null),
      POST,
      data,
      resource,
      timeout,
    );
  }

  /**
   * Web service de type REST pour supprimer une ressource
   * @category RequestUtils
   * @param {string} urlBase URL de base
   * @param {string} resource Nom de la ressource
   * @param {string} id Id de la ressource à supprimer
   * @param {number} [timeout=120000] Timeout en ms
   */
  static deleteResource(urlBase, token, resource, id, timeout) {
    return RequestUtils._sendRequest(
      token,
      RequestUtils._constructUrl(urlBase, resource, id, null, timeout = 120000),
      DELETE,
      null,
      resource,
      timeout
    );
  }

  /**
 * Requête avec timeout
 * @category RequestUtils
 * @param {string} url URL de la requête
 * @param {object} options Options de la requête
 * @param {number} timeout Timeout en ms
 */
  static fetchWithTimeout(url, options, timeout) {
    return Promise.race([
      fetch(url, options),
      new Promise((_, reject) => {
        setTimeout(() => reject(new Error("timeout")), timeout);
      })
    ]);
  }

  /**
   * Web service de type REST pour récupérer une ressource
   * @category RequestUtils
   * @param {string} urlBase URL de base
   * @param {string} resource Nom de la ressource
   * @param {string} id Id de la ressource à récupérer
   * @param {number} [timeout=120000] Timeout en ms
   */
  static getResource(urlBase, token, resource, id, timeout) {
    return RequestUtils._sendRequest(
      token,
      RequestUtils._constructUrl(urlBase, resource, id, null, timeout = 120000),
      GET,
      null,
      resource,
      timeout
    );
  }

  /**
* Web service de type REST avec gestion de paramètres pour récupérer une ressource
* @category RequestUtils
* @param {string} urlBase URL de base
* @param {string} resource Nom de la ressource
* @param {string} id Id de la ressource à récupérer
* @param {array} params Paramètres de l'url sous forme d'un tableau de chaines clé=valeur ["cle1=val1", "cle2=val2"]
* @param {number} [timeout=120000] Timeout en ms
*/
  static getResourceWithParams(urlBase, token, resource, id, params, timeout = 120000) {
    return RequestUtils._sendRequest(
      token,
      RequestUtils._constructUrl(urlBase, resource, id, params),
      GET,
      null,
      resource,
      timeout
    );
  }

  /**
   * Web service de type REST pour récupérer une liste de ressources
   * @category RequestUtils
   * @param {string} urlBase URL de base
   * @param {string} resource Nom de la ressource
   * @param {number} [timeout=120000] Timeout en ms
   */
  static getResources(urlBase, token, resource, timeout = 120000) {
    return RequestUtils._sendRequest(
      token,
      RequestUtils._constructUrl(urlBase, resource, null, null),
      GET,
      null,
      resource,
      timeout
    );
  }

  /**
   * Web service de type REST avec gestion de paramètres pour récupérer une liste de ressources
   * @category RequestUtils
   * @param {string} urlBase URL de base
   * @param {string} resource Nom de la ressource
   * @param {array} params Paramètres de l'url sous forme d'un tableau de chaines clé=valeur ["cle1=val1", "cle2=val2"]
   * @param {number} [timeout=120000] Timeout en ms
   */
  static getResourcesWithParams(urlBase, token, resource, params, timeout = 120000) {
    return RequestUtils._sendRequest(
      token,
      RequestUtils._constructUrl(urlBase, resource, null, params),
      GET,
      null,
      resource,
      timeout
    );
  }

  /**
   * Web service de type REST pour mettre à jour une ressource
   * @category RequestUtils
   * @param {string} urlBase URLl de base
   * @param {string} resource Nom de la ressource
   * @param {string} id Id de la ressource à modifier
   * @param {object} data Données à envoyer au format JSON
   * @param {number} [timeout=120000] Timeout en ms
   */
  static updateResource(urlBase, token, resource, id, data, timeout = 120000) {
    return RequestUtils._sendRequest(
      token,
      RequestUtils._constructUrl(urlBase, resource, id, null),
      PATCH,
      data,
      resource,
      timeout
    );
  }
  // #endregion

  // #region METHODES PRIVEES
  /**
   * Fonction permettant de construire l'url
   * @category RequestUtils
   * @private
   * @param {string} urlBase URL de base
   * @param {string} resource Nom de la ressource
   * @param {string} id Id de la ressource
   * @param {array} params Paramètres de l'url sous forme d'un tableau de chaines clé=valeur ["cle1=val1", "cle2=val2"]
   */
  static _constructUrl(urlBase, resource, id, params) {
    let finalUrl = urlBase + "/" + resource;

    // Gestion d'un id
    if (id) {
      finalUrl += "/" + id;
    }

    // Gestion de paramètres
    if (params) {
      finalUrl += "?";
      for (let i = 0; i < params.length; i++) {
        if (i > 0) {
          finalUrl += "&";
        }
        finalUrl += params[i];
      }
    }

    return finalUrl;
  }

  /**
   * Fonction asynchrone d'envoi d'une requête au serveur
   * @category RequestUtils
   * @private
   * @param {string} url URL de la requête
   * @param {string} method Méthode de la requête (GET, POST, PATCH ou DELETE)
   * @param {object} data Données de la requête au format JSON
   * @param {string} resource Nom de la ressource
   * @param {number} timeout Timeout en ms
   */
  static async _sendRequest(token, url, method, data, resource, timeout) {
    // Configuration de la requête
    let requestConf = {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };

    if (token) {
      // Ajout de l'id de session dans les headers
      requestConf.headers = { ...requestConf.headers, "Authorization": "Bearer " + token };
    }

    // Pas de body si GET ou données vide
    if (method !== GET && data) {
      if (method === PATCH) {
        requestConf.headers["Content-Type"] = "application/merge-patch+json";
      }
      requestConf.body = JSON.stringify(data);
    }

    // Envoi de la requête au serveur avec timeout
    let resultRequest = await fetchWithTimeout(url, requestConf, timeout)
      .then(response => response)
      .catch(error => error.message);

    // Traitement de la réponse via une promesse
    return new Promise(async (resolve, reject) => {
      if (typeof resultRequest === "string") {
        if ((resultRequest.toLowerCase() === "network request failed") || (resultRequest.toLowerCase() === "timeout")) {
          reject({ error: "Connexion au serveur impossible, vérifiez l'état du réseau et les paramètres de connexion", status: TIMEOUT });
        } else {
          reject({ error: resultRequest, status: 0 });
        }
      }

      let resp = await RequestUtils._manageResponse(resultRequest, resource);
      if (resp.result) {
        resolve(resp.response);
      }

      reject({ error: resp.error, status: resp.status });
    });
  }

  /**
   * Fonction permettant de traiter la réponse
   * @category RequestUtils
   * @private
   * @param {object} response Les données de la réponse au format JSON
   * @param {string} resource Nom de la ressource
   * @returns {object} Objet contenant les informations suivantes : result (true/false), response (données de la réponse au forma JSON), error (message d'erreur si présent), statuts (code HTTP de retour)
   */
  static async _manageResponse(response, resource) {
    let result = {
      result: false,
      response: null,
      error: null,
      status: response.status
    };

    if (response.ok) {
      result.result = true;
      result.response = response.json();
    } else if (response.status) {
      switch (response.status) {
        case UNAUTHORIZED:
          break;

        case FORBIDDEN:
          result.error = "Accès interdit";
          break;

        case NOT_FOUND:
          // result.error = "Aucune donnée trouvée";
          break;

        case NOT_SUPPORTED:
          result.error = "Opération non prise en charge";
          break;

        case DATA_ERROR:
          // On attend le résultat avec await
          let dataErrorMsg = await response
            .json()
            .then(response => {
              if (response.erreur) {
                return response.erreur;
              } else if (response[resource] && response[resource].erreur) {
                return response[resource].erreur;
              }
              return null;
            })
            .catch(error => error);
          result.error = dataErrorMsg;
          break;

        case SERVER_ERROR:
          let serverErrorMsg = await response
            .text()
            .then(response => response)
            .catch(error => error);
          result.error = serverErrorMsg;
          break;

        default:
          break;
      }
    } else {
      result.error = "Erreur lors de l'exécution de la requête";
    }

    return result;
  }
  // #endregion
}

// Exports
export const createResource = RequestUtils.createResource;
export const deleteResource = RequestUtils.deleteResource;
export const fetchWithTimeout = RequestUtils.fetchWithTimeout;
export const getResource = RequestUtils.getResource;
export const getResourceWithParams = RequestUtils.getResourceWithParams;
export const getResources = RequestUtils.getResources;
export const getResourcesWithParams = RequestUtils.getResourcesWithParams;
export const updateResource = RequestUtils.updateResource;
