import { StyleSheet, Font } from '@react-pdf/renderer';
import Roboto from "Resources/Fonts/Roboto-Regular.ttf";
import RobotoBold from "Resources/Fonts/Roboto-Bold.ttf";

// Register font
Font.register({
  family: "Roboto",
  fonts: [
    { src: Roboto },
    { src: RobotoBold, fontWeight: 700 }
  ]
});

// #region STYLES
export const styles = StyleSheet.create({
  TicketPayzen_CodeContainer: {
    alignItems: "center",
    flexDirection: "row",
    paddingTop: 24
  },
  TicketPayzen_HeaderText: {
    color: "#101223",
    fontSize: "19px",
    width: "50%",
  },
  TicketPayzen_Image: {
    height: "32px",
    width: "120px",
    marginBottom: "15px"
  },
  TicketPayzen_Page: {
    backgroundColor: "white",
    padding: 5
  },
  TicketPayzen_SectionHeaderContainer: {
    borderBottom: "1px solid #35A7D7"
  },
  TicketPayzen_Text: {
    color: "#101223",
    fontFamily: "Roboto",
    fontSize: "12px",
  },
  TicketPayzen_TextContainer: {
    alignItems: "center",
    flexDirection: "row"
  },
  TicketPayzen_TextLeft: {
  },
  TicketPayzen_TextRight: {
    marginLeft: "auto",
  },
  TicketPayzen_Title: {
    fontSize: "17px",
    fontFamily: "Roboto",
    fontWeight: "bold"
  },
});
//#endregion
